import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Typography } from 'antd';
import ReCAPTCHA from 'react-google-recaptcha-enterprise';
import formatPhoneNumber from 'utils/formatPhoneNumber.js';
import useTranslation from 'utils/hooks/useTranslation';
import { isValidDriverPassword } from 'utils/passwordUtils';
import useAmplitude from 'utils/hooks/useAmplitude';
import useIsFormValid from 'utils/hooks/useIsFormValid';
import SocialSignIn from './SocialSignIn';
import { AMPLITUDE_EVENT_TYPES } from 'constants/amplitude';
import { FORM_FIELD_NAMES } from 'constants/selfRegistration';
import { RECAPTCHA_SITE_KEY } from 'constants.js';

import styles from './style.module.scss';

const RegistrationForm = ({
  driverInfo,
  onRegister,
  renderAppleSignInButton,
  enableFacebookSignIn,
  registrationCode,
}) => {
  const [recaptchaToken, setRecaptchaToken] = useState('');

  const { sendAmplitudeEvent } = useAmplitude();
  const { translateText } = useTranslation();

  const [form] = Form.useForm();
  const { validateFields } = form;

  const { isFormValid, validateForm } = useIsFormValid(form);

  const handleSubmit = () => {
    sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.onboardingDriverRegistrationButton);
    validateFields()
      .then(values => {
        onRegister({ password: values.password, recaptchaToken });
      })
      .catch(() => {});
  };

  const validateMessages = {
    types: {
      password: '${value}',
    },
  };

  const generateToken = token => {
    setRecaptchaToken(token);
  };

  useEffect(() => {
    sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.onboardingDriverRegistrationForm);
  }, [sendAmplitudeEvent]);

  return (
    <div className={styles.registrationForm} data-testid="registrationForm">
      <Typography.Title level={4} className={styles.registrationFormTitle}>
        <p>{translateText('corporateAccounts.onboarding.registration.formTitle')}</p>
      </Typography.Title>
      <Typography.Paragraph className={styles.registrationDescription}>
        <p>
          {translateText('corporateAccounts.onboarding.registration.formDescription', {
            companyName: driverInfo.corporateClientName,
          })}
        </p>
      </Typography.Paragraph>
      <Form
        form={form}
        layout="vertical"
        validateMessages={validateMessages}
        onFieldsChange={validateForm}
      >
        <Form.Item label={translateText('corporateAccounts.reports.phoneNumber')} size="small">
          <Input
            defaultValue={formatPhoneNumber(driverInfo.mobilePhoneNumber)}
            disabled={true}
            data-testid={FORM_FIELD_NAMES.mobilePhoneNumber}
          />
        </Form.Item>
        <Form.Item
          name={FORM_FIELD_NAMES.password}
          label={translateText('corporateAccounts.common.password')}
          validateFirst
          data-testid={FORM_FIELD_NAMES.password}
          rules={[
            {
              required: true,
              message: 'Please enter a password',
            },
            {
              type: 'password',
              validator: (rule, value) =>
                value ? isValidDriverPassword(value, translateText) : Promise.resolve(),
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <ReCAPTCHA
          data-testid="recaptcha"
          id="recaptcha"
          sitekey={RECAPTCHA_SITE_KEY}
          onChange={generateToken}
          onExpired={() => setRecaptchaToken(null)}
        />
      </Form>
      <div className={styles.registrationButton}>
        <Button
          type="primary"
          size="large"
          onClick={() => handleSubmit()}
          disabled={!isFormValid || !recaptchaToken}
        >
          {translateText('corporateAccounts.actions.register')}
        </Button>
      </div>
      <SocialSignIn
        renderAppleSignInButton={renderAppleSignInButton}
        enableFacebookSignIn={enableFacebookSignIn}
        registrationCode={registrationCode}
      />
    </div>
  );
};

export default RegistrationForm;
