module.exports = {
  'Corporate Accounts Key': 'Corporate Accounts Key',
  TEST: 'TEST',
  'TEST.INTERPOLATIONS': 'TEST.INTERPOLATIONS',
  'corporateAccounts.actions': 'corporateAccounts.actions',
  'corporateAccounts.actions.addNewAccount': 'corporateAccounts.actions.addNewAccount',
  'corporateAccounts.actions.back': 'corporateAccounts.actions.back',
  'corporateAccounts.actions.browse': 'corporateAccounts.actions.browse',
  'corporateAccounts.actions.cancel': 'corporateAccounts.actions.cancel',
  'corporateAccounts.actions.chooseCsv': 'corporateAccounts.actions.chooseCsv',
  'corporateAccounts.actions.continue': 'corporateAccounts.actions.continue',
  'corporateAccounts.actions.deactivate': 'corporateAccounts.actions.deactivate',
  'corporateAccounts.actions.deactivateAccount': 'corporateAccounts.actions.deactivateAccount',
  'corporateAccounts.actions.delete': 'corporateAccounts.actions.delete',
  'corporateAccounts.actions.done': 'corporateAccounts.actions.done',
  'corporateAccounts.actions.download': 'corporateAccounts.actions.download',
  'corporateAccounts.actions.downloadLogFile': 'corporateAccounts.actions.downloadLogFile',
  'corporateAccounts.actions.edit': 'corporateAccounts.actions.edit',
  'corporateAccounts.actions.export': 'corporateAccounts.actions.export',
  'corporateAccounts.actions.exportData': 'corporateAccounts.actions.exportData',
  'corporateAccounts.actions.goToPage': 'corporateAccounts.actions.goToPage',
  'corporateAccounts.actions.importing': 'corporateAccounts.actions.importing',
  'corporateAccounts.actions.migrate': 'corporateAccounts.actions.migrate',
  'corporateAccounts.actions.next': 'corporateAccounts.actions.next',
  'corporateAccounts.actions.reactivate': 'corporateAccounts.actions.reactivate',
  'corporateAccounts.actions.reactivateAccount': 'corporateAccounts.actions.reactivateAccount',
  'corporateAccounts.actions.register': 'corporateAccounts.actions.register',
  'corporateAccounts.actions.replaceFile': 'corporateAccounts.actions.replaceFile',
  'corporateAccounts.actions.resendInvite': 'corporateAccounts.actions.resendInvite',
  'corporateAccounts.actions.resendInvite.error': 'corporateAccounts.actions.resendInvite.error',
  'corporateAccounts.actions.resendInvite.success':
    'corporateAccounts.actions.resendInvite.success',
  'corporateAccounts.actions.save': 'corporateAccounts.actions.save',
  'corporateAccounts.actions.sendInvite': 'corporateAccounts.actions.sendInvite',
  'corporateAccounts.admin.accounts': 'corporateAccounts.admin.accounts',
  'corporateAccounts.admin.action.enterAccount': 'corporateAccounts.admin.action.enterAccount',
  'corporateAccounts.admin.action.more': 'corporateAccounts.admin.action.more',
  'corporateAccounts.admin.action.moreActions': 'corporateAccounts.admin.action.moreActions',
  'corporateAccounts.admin.add.success': 'corporateAccounts.admin.add.success',
  'corporateAccounts.admin.corporation': 'corporateAccounts.admin.corporation',
  'corporateAccounts.admin.delete.success': 'corporateAccounts.admin.delete.success',
  'corporateAccounts.admin.invite.success': 'corporateAccounts.admin.invite.success',
  'corporateAccounts.admin.newCorporations': 'corporateAccounts.admin.newCorporations',
  'corporateAccounts.admin.payment': 'corporateAccounts.admin.payment',
  'corporateAccounts.admin.paymentInfo': 'corporateAccounts.admin.paymentInfo',
  'corporateAccounts.admin.search': 'corporateAccounts.admin.search',
  'corporateAccounts.admin.update.success': 'corporateAccounts.admin.update.success',
  'corporateAccounts.admins.actions.addUser': 'corporateAccounts.admins.actions.addUser',
  'corporateAccounts.admins.actions.deleteUser': 'corporateAccounts.admins.actions.deleteUser',
  'corporateAccounts.admins.actions.sendInviteMessage':
    'corporateAccounts.admins.actions.sendInviteMessage',
  'corporateAccounts.admins.activate.error.message':
    'corporateAccounts.admins.activate.error.message',
  'corporateAccounts.admins.activate.error.title': 'corporateAccounts.admins.activate.error.title',
  'corporateAccounts.admins.corporateClientId': 'corporateAccounts.admins.corporateClientId',
  'corporateAccounts.admins.corporationName': 'corporateAccounts.admins.corporationName',
  'corporateAccounts.admins.deactivate.banner.message':
    'corporateAccounts.admins.deactivate.banner.message',
  'corporateAccounts.admins.deactivate.error.body':
    'corporateAccounts.admins.deactivate.error.body',
  'corporateAccounts.admins.deactivate.error.title':
    'corporateAccounts.admins.deactivate.error.title',
  'corporateAccounts.admins.deactivate.modal.confirm.body':
    'corporateAccounts.admins.deactivate.modal.confirm.body',
  'corporateAccounts.admins.deactivate.modal.confirm.title':
    'corporateAccounts.admins.deactivate.modal.confirm.title',
  'corporateAccounts.admins.deactivate.success': 'corporateAccounts.admins.deactivate.success',
  'corporateAccounts.admins.delete.error.body': 'corporateAccounts.admins.delete.error.body',
  'corporateAccounts.admins.delete.error.title': 'corporateAccounts.admins.delete.error.title',
  'corporateAccounts.admins.delete.modal.confirm.body':
    'corporateAccounts.admins.delete.modal.confirm.body',
  'corporateAccounts.admins.delete.modal.confirm.title':
    'corporateAccounts.admins.delete.modal.confirm.title',
  'corporateAccounts.admins.delete.success': 'corporateAccounts.admins.delete.success',
  'corporateAccounts.admins.editUser': 'corporateAccounts.admins.editUser',
  'corporateAccounts.admins.email': 'corporateAccounts.admins.email',
  'corporateAccounts.admins.firstName': 'corporateAccounts.admins.firstName',
  'corporateAccounts.admins.form.emailPlaceholder':
    'corporateAccounts.admins.form.emailPlaceholder',
  'corporateAccounts.admins.form.emailRequired': 'corporateAccounts.admins.form.emailRequired',
  'corporateAccounts.admins.form.firstNamePlaceholder':
    'corporateAccounts.admins.form.firstNamePlaceholder',
  'corporateAccounts.admins.form.firstNameRequired':
    'corporateAccounts.admins.form.firstNameRequired',
  'corporateAccounts.admins.form.lastNamePlaceholder':
    'corporateAccounts.admins.form.lastNamePlaceholder',
  'corporateAccounts.admins.form.lastNameRequired':
    'corporateAccounts.admins.form.lastNameRequired',
  'corporateAccounts.admins.form.phoneRequired': 'corporateAccounts.admins.form.phoneRequired',
  'corporateAccounts.admins.form.roleRequired': 'corporateAccounts.admins.form.roleRequired',
  'corporateAccounts.admins.form.title.edit': 'corporateAccounts.admins.form.title.edit',
  'corporateAccounts.admins.form.title.new': 'corporateAccounts.admins.form.title.new',
  'corporateAccounts.admins.lastName': 'corporateAccounts.admins.lastName',
  'corporateAccounts.admins.main.title': 'corporateAccounts.admins.main.title',
  'corporateAccounts.admins.modal.downgradeAccountOwner.content':
    'corporateAccounts.admins.modal.downgradeAccountOwner.content',
  'corporateAccounts.admins.modal.downgradeAccountOwner.title':
    'corporateAccounts.admins.modal.downgradeAccountOwner.title',
  'corporateAccounts.admins.modal.upgradeBusinessManager.content':
    'corporateAccounts.admins.modal.upgradeBusinessManager.content',
  'corporateAccounts.admins.modal.upgradeBusinessManager.title':
    'corporateAccounts.admins.modal.upgradeBusinessManager.title',
  'corporateAccounts.admins.phone': 'corporateAccounts.admins.phone',
  'corporateAccounts.admins.phoneNumber': 'corporateAccounts.admins.phoneNumber',
  'corporateAccounts.admins.reactivate.error.body':
    'corporateAccounts.admins.reactivate.error.body',
  'corporateAccounts.admins.reactivate.error.title':
    'corporateAccounts.admins.reactivate.error.title',
  'corporateAccounts.admins.reactivate.modal.confirm.body':
    'corporateAccounts.admins.reactivate.modal.confirm.body',
  'corporateAccounts.admins.reactivate.modal.confirm.title':
    'corporateAccounts.admins.reactivate.modal.confirm.title',
  'corporateAccounts.admins.reactivate.success': 'corporateAccounts.admins.reactivate.success',
  'corporateAccounts.admins.role': 'corporateAccounts.admins.role',
  'corporateAccounts.admins.role.faq': 'corporateAccounts.admins.role.faq',
  'corporateAccounts.admins.role.faq.title': 'corporateAccounts.admins.role.faq.title',
  'corporateAccounts.admins.role.fleet_manager': 'corporateAccounts.admins.role.fleet_manager',
  'corporateAccounts.admins.role.fleet_manager.description':
    'corporateAccounts.admins.role.fleet_manager.description',
  'corporateAccounts.admins.role.primary_fleet_manager':
    'corporateAccounts.admins.role.primary_fleet_manager',
  'corporateAccounts.admins.role.primary_fleet_manager.description':
    'corporateAccounts.admins.role.primary_fleet_manager.description',
  'corporateAccounts.admins.role.reporting': 'corporateAccounts.admins.role.reporting',
  'corporateAccounts.admins.role.reporting.description':
    'corporateAccounts.admins.role.reporting.description',
  'corporateAccounts.admins.role.service_admin': 'corporateAccounts.admins.role.service_admin',
  'corporateAccounts.admins.role.service_admin.description':
    'corporateAccounts.admins.role.service_admin.description',
  'corporateAccounts.admins.status.activating': 'corporateAccounts.admins.status.activating',
  'corporateAccounts.admins.status.completeActivation':
    'corporateAccounts.admins.status.completeActivation',
  'corporateAccounts.admins.status.reinviteRequired':
    'corporateAccounts.admins.status.reinviteRequired',
  'corporateAccounts.auditLogs.auditDetails.description':
    'corporateAccounts.auditLogs.auditDetails.description',
  'corporateAccounts.auditLogs.auditDetails.id': 'corporateAccounts.auditLogs.auditDetails.id',
  'corporateAccounts.auditLogs.auditDetails.title':
    'corporateAccounts.auditLogs.auditDetails.title',
  'corporateAccounts.auditLogs.auditDetails.workflowId':
    'corporateAccounts.auditLogs.auditDetails.workflowId',
  'corporateAccounts.auditLogs.emptyState.description':
    'corporateAccounts.auditLogs.emptyState.description',
  'corporateAccounts.auditLogs.searchInput.placeholder':
    'corporateAccounts.auditLogs.searchInput.placeholder',
  'corporateAccounts.auditLogs.table.clickForDetails':
    'corporateAccounts.auditLogs.table.clickForDetails',
  'corporateAccounts.auditLogs.tableColumn.userAction':
    'corporateAccounts.auditLogs.tableColumn.userAction',
  'corporateAccounts.auditLogs.tableColumn.userRole':
    'corporateAccounts.auditLogs.tableColumn.userRole',
  'corporateAccounts.auth.redirectMessage': 'corporateAccounts.auth.redirectMessage',
  'corporateAccounts.auth.sessionExpired': 'corporateAccounts.auth.sessionExpired',
  'corporateAccounts.billing.billingAddress': 'corporateAccounts.billing.billingAddress',
  'corporateAccounts.billing.subscription': 'corporateAccounts.billing.subscription',
  'corporateAccounts.breadcrumbs.adminUsers': 'corporateAccounts.breadcrumbs.adminUsers',
  'corporateAccounts.breadcrumbs.create': 'corporateAccounts.breadcrumbs.create',
  'corporateAccounts.breadcrumbs.drivers': 'corporateAccounts.breadcrumbs.drivers',
  'corporateAccounts.breadcrumbs.edit': 'corporateAccounts.breadcrumbs.edit',
  'corporateAccounts.breadcrumbs.monthlyStatements':
    'corporateAccounts.breadcrumbs.monthlyStatements',
  'corporateAccounts.breadcrumbs.payments': 'corporateAccounts.breadcrumbs.payments',
  'corporateAccounts.breadcrumbs.reports': 'corporateAccounts.breadcrumbs.reports',
  'corporateAccounts.breadcrumbs.sessions': 'corporateAccounts.breadcrumbs.sessions',
  'corporateAccounts.breadcrumbs.upload': 'corporateAccounts.breadcrumbs.upload',
  'corporateAccounts.breadcrumbs.vehicles': 'corporateAccounts.breadcrumbs.vehicles',
  'corporateAccounts.button.addSingleDriver': 'corporateAccounts.button.addSingleDriver',
  'corporateAccounts.button.addSingleVehicle': 'corporateAccounts.button.addSingleVehicle',
  'corporateAccounts.button.bulkUpload': 'corporateAccounts.button.bulkUpload',
  'corporateAccounts.common.all': 'corporateAccounts.common.all',
  'corporateAccounts.common.businessEmail': 'corporateAccounts.common.businessEmail',
  'corporateAccounts.common.businessName': 'corporateAccounts.common.businessName',
  'corporateAccounts.common.contact': 'corporateAccounts.common.contact',
  'corporateAccounts.common.contactSales': 'corporateAccounts.common.contactSales',
  'corporateAccounts.common.costCenterInfo': 'corporateAccounts.common.costCenterInfo',
  'corporateAccounts.common.date': 'corporateAccounts.common.date',
  'corporateAccounts.common.details': 'corporateAccounts.common.details',
  'corporateAccounts.common.directDebit': 'corporateAccounts.common.directDebit',
  'corporateAccounts.common.download': 'corporateAccounts.common.download',
  'corporateAccounts.common.driver': 'corporateAccounts.common.driver',
  'corporateAccounts.common.empty': 'corporateAccounts.common.empty',
  'corporateAccounts.common.error': 'corporateAccounts.common.error',
  'corporateAccounts.common.error.characterCountExceeded':
    'corporateAccounts.common.error.characterCountExceeded',
  'corporateAccounts.common.error.somethingWentWrong':
    'corporateAccounts.common.error.somethingWentWrong',
  'corporateAccounts.common.errorCode': 'corporateAccounts.common.errorCode',
  'corporateAccounts.common.errorCode.copy': 'corporateAccounts.common.errorCode.copy',
  'corporateAccounts.common.frequency.biweekly': 'corporateAccounts.common.frequency.biweekly',
  'corporateAccounts.common.frequency.monthly': 'corporateAccounts.common.frequency.monthly',
  'corporateAccounts.common.frequency.unknown': 'corporateAccounts.common.frequency.unknown',
  'corporateAccounts.common.frequency.weekly': 'corporateAccounts.common.frequency.weekly',
  'corporateAccounts.common.help': 'corporateAccounts.common.help',
  'corporateAccounts.common.invitationCode': 'corporateAccounts.common.invitationCode',
  'corporateAccounts.common.invitationStatus': 'corporateAccounts.common.invitationStatus',
  'corporateAccounts.common.month': 'corporateAccounts.common.month',
  'corporateAccounts.common.new': 'corporateAccounts.common.new',
  'corporateAccounts.common.no': 'corporateAccounts.common.no',
  'corporateAccounts.common.password': 'corporateAccounts.common.password',
  'corporateAccounts.common.password.length': 'corporateAccounts.common.password.length',
  'corporateAccounts.common.password.lowercase': 'corporateAccounts.common.password.lowercase',
  'corporateAccounts.common.password.number': 'corporateAccounts.common.password.number',
  'corporateAccounts.common.password.specialChar': 'corporateAccounts.common.password.specialChar',
  'corporateAccounts.common.password.uppercase': 'corporateAccounts.common.password.uppercase',
  'corporateAccounts.common.passwordRequired': 'corporateAccounts.common.passwordRequired',
  'corporateAccounts.common.pleaseTryAgain': 'corporateAccounts.common.pleaseTryAgain',
  'corporateAccounts.common.privacyPolicy': 'corporateAccounts.common.privacyPolicy',
  'corporateAccounts.common.quarter': 'corporateAccounts.common.quarter',
  'corporateAccounts.common.questions': 'corporateAccounts.common.questions',
  'corporateAccounts.common.remove': 'corporateAccounts.common.remove',
  'corporateAccounts.common.rowNumber': 'corporateAccounts.common.rowNumber',
  'corporateAccounts.common.saveSuccessful': 'corporateAccounts.common.saveSuccessful',
  'corporateAccounts.common.search': 'corporateAccounts.common.search',
  'corporateAccounts.common.statements': 'corporateAccounts.common.statements',
  'corporateAccounts.common.status': 'corporateAccounts.common.status',
  'corporateAccounts.common.status.active': 'corporateAccounts.common.status.active',
  'corporateAccounts.common.status.added': 'corporateAccounts.common.status.added',
  'corporateAccounts.common.status.bulkAdded': 'corporateAccounts.common.status.bulkAdded',
  'corporateAccounts.common.status.bulkDeleted': 'corporateAccounts.common.status.bulkDeleted',
  'corporateAccounts.common.status.close': 'corporateAccounts.common.status.close',
  'corporateAccounts.common.status.closed': 'corporateAccounts.common.status.closed',
  'corporateAccounts.common.status.deactivated': 'corporateAccounts.common.status.deactivated',
  'corporateAccounts.common.status.deleted': 'corporateAccounts.common.status.deleted',
  'corporateAccounts.common.status.expired': 'corporateAccounts.common.status.expired',
  'corporateAccounts.common.status.inactive': 'corporateAccounts.common.status.inactive',
  'corporateAccounts.common.status.inviteExpired': 'corporateAccounts.common.status.inviteExpired',
  'corporateAccounts.common.status.inviteFailed': 'corporateAccounts.common.status.inviteFailed',
  'corporateAccounts.common.status.inviteSent': 'corporateAccounts.common.status.inviteSent',
  'corporateAccounts.common.status.invited': 'corporateAccounts.common.status.invited',
  'corporateAccounts.common.status.modified': 'corporateAccounts.common.status.modified',
  'corporateAccounts.common.status.new': 'corporateAccounts.common.status.new',
  'corporateAccounts.common.status.notCompleted': 'corporateAccounts.common.status.notCompleted',
  'corporateAccounts.common.status.notInvited': 'corporateAccounts.common.status.notInvited',
  'corporateAccounts.common.status.onHold': 'corporateAccounts.common.status.onHold',
  'corporateAccounts.common.status.onboarding': 'corporateAccounts.common.status.onboarding',
  'corporateAccounts.common.status.passive': 'corporateAccounts.common.status.passive',
  'corporateAccounts.common.status.pending': 'corporateAccounts.common.status.pending',
  'corporateAccounts.common.status.previous': 'corporateAccounts.common.status.previous',
  'corporateAccounts.common.status.prospect': 'corporateAccounts.common.status.prospect',
  'corporateAccounts.common.status.rejected': 'corporateAccounts.common.status.rejected',
  'corporateAccounts.common.subscriptionAgreement':
    'corporateAccounts.common.subscriptionAgreement',
  'corporateAccounts.common.switchToDesktop': 'corporateAccounts.common.switchToDesktop',
  'corporateAccounts.common.switchToDesktop.description':
    'corporateAccounts.common.switchToDesktop.description',
  'corporateAccounts.common.termsAndConditions': 'corporateAccounts.common.termsAndConditions',
  'corporateAccounts.common.termsAndPrivacyAgreementRequired':
    'corporateAccounts.common.termsAndPrivacyAgreementRequired',
  'corporateAccounts.common.type': 'corporateAccounts.common.type',
  'corporateAccounts.common.unexpectedError': 'corporateAccounts.common.unexpectedError',
  'corporateAccounts.common.update': 'corporateAccounts.common.update',
  'corporateAccounts.common.upload': 'corporateAccounts.common.upload',
  'corporateAccounts.common.user': 'corporateAccounts.common.user',
  'corporateAccounts.common.vehicle': 'corporateAccounts.common.vehicle',
  'corporateAccounts.common.week': 'corporateAccounts.common.week',
  'corporateAccounts.common.year': 'corporateAccounts.common.year',
  'corporateAccounts.common.yes': 'corporateAccounts.common.yes',
  'corporateAccounts.copyright': 'corporateAccounts.copyright',
  'corporateAccounts.copyright.allRightsReserved': 'corporateAccounts.copyright.allRightsReserved',
  'corporateAccounts.corporateClient.SpaCards': 'corporateAccounts.corporateClient.SpaCards',
  'corporateAccounts.corporateClient.accountOwnerStatus':
    'corporateAccounts.corporateClient.accountOwnerStatus',
  'corporateAccounts.corporateClient.accountType': 'corporateAccounts.corporateClient.accountType',
  'corporateAccounts.corporateClient.accountType.makeTestAccount':
    'corporateAccounts.corporateClient.accountType.makeTestAccount',
  'corporateAccounts.corporateClient.accountType.standard':
    'corporateAccounts.corporateClient.accountType.standard',
  'corporateAccounts.corporateClient.accountType.test':
    'corporateAccounts.corporateClient.accountType.test',
  'corporateAccounts.corporateClient.activate.error.message':
    'corporateAccounts.corporateClient.activate.error.message',
  'corporateAccounts.corporateClient.address.error.presence':
    'corporateAccounts.corporateClient.address.error.presence',
  'corporateAccounts.corporateClient.billing.Info':
    'corporateAccounts.corporateClient.billing.Info',
  'corporateAccounts.corporateClient.billing.edit.error.message':
    'corporateAccounts.corporateClient.billing.edit.error.message',
  'corporateAccounts.corporateClient.billing.edit.error.title':
    'corporateAccounts.corporateClient.billing.edit.error.title',
  'corporateAccounts.corporateClient.billing.edit.success':
    'corporateAccounts.corporateClient.billing.edit.success',
  'corporateAccounts.corporateClient.businessName.validationError.businessNameRequired':
    'corporateAccounts.corporateClient.businessName.validationError.businessNameRequired',
  'corporateAccounts.corporateClient.businessName.validationError.characterLimitExceeded':
    'corporateAccounts.corporateClient.businessName.validationError.characterLimitExceeded',
  'corporateAccounts.corporateClient.city': 'corporateAccounts.corporateClient.city',
  'corporateAccounts.corporateClient.city.error.presence':
    'corporateAccounts.corporateClient.city.error.presence',
  'corporateAccounts.corporateClient.clientStatus':
    'corporateAccounts.corporateClient.clientStatus',
  'corporateAccounts.corporateClient.companyAddress1':
    'corporateAccounts.corporateClient.companyAddress1',
  'corporateAccounts.corporateClient.companyAddress2':
    'corporateAccounts.corporateClient.companyAddress2',
  'corporateAccounts.corporateClient.corporationNumber':
    'corporateAccounts.corporateClient.corporationNumber',
  'corporateAccounts.corporateClient.corporationNumber.error.presence':
    'corporateAccounts.corporateClient.corporationNumber.error.presence',
  'corporateAccounts.corporateClient.country': 'corporateAccounts.corporateClient.country',
  'corporateAccounts.corporateClient.country.error.presence':
    'corporateAccounts.corporateClient.country.error.presence',
  'corporateAccounts.corporateClient.country.placeholder':
    'corporateAccounts.corporateClient.country.placeholder',
  'corporateAccounts.corporateClient.create.error':
    'corporateAccounts.corporateClient.create.error',
  'corporateAccounts.corporateClient.create.success':
    'corporateAccounts.corporateClient.create.success',
  'corporateAccounts.corporateClient.createAccount':
    'corporateAccounts.corporateClient.createAccount',
  'corporateAccounts.corporateClient.crnNo': 'corporateAccounts.corporateClient.crnNo',
  'corporateAccounts.corporateClient.dateCreated': 'corporateAccounts.corporateClient.dateCreated',
  'corporateAccounts.corporateClient.edit.error': 'corporateAccounts.corporateClient.edit.error',
  'corporateAccounts.corporateClient.edit.success':
    'corporateAccounts.corporateClient.edit.success',
  'corporateAccounts.corporateClient.email': 'corporateAccounts.corporateClient.email',
  'corporateAccounts.corporateClient.fleetSize': 'corporateAccounts.corporateClient.fleetSize',
  'corporateAccounts.corporateClient.fleetSize.error.presence':
    'corporateAccounts.corporateClient.fleetSize.error.presence',
  'corporateAccounts.corporateClient.fleetSize.tooltip':
    'corporateAccounts.corporateClient.fleetSize.tooltip',
  'corporateAccounts.corporateClient.fleetSize.validationError.fleetSizeRequired':
    'corporateAccounts.corporateClient.fleetSize.validationError.fleetSizeRequired',
  'corporateAccounts.corporateClient.fleetSize.validationError.maxDigitsExceeded':
    'corporateAccounts.corporateClient.fleetSize.validationError.maxDigitsExceeded',
  'corporateAccounts.corporateClient.fleetSize.validationError.numericOnly':
    'corporateAccounts.corporateClient.fleetSize.validationError.numericOnly',
  'corporateAccounts.corporateClient.industry': 'corporateAccounts.corporateClient.industry',
  'corporateAccounts.corporateClient.industry.placeholder':
    'corporateAccounts.corporateClient.industry.placeholder',
  'corporateAccounts.corporateClient.industryOption.carRental':
    'corporateAccounts.corporateClient.industryOption.carRental',
  'corporateAccounts.corporateClient.industryOption.cleaning':
    'corporateAccounts.corporateClient.industryOption.cleaning',
  'corporateAccounts.corporateClient.industryOption.construction':
    'corporateAccounts.corporateClient.industryOption.construction',
  'corporateAccounts.corporateClient.industryOption.delivery':
    'corporateAccounts.corporateClient.industryOption.delivery',
  'corporateAccounts.corporateClient.industryOption.electricVehicles':
    'corporateAccounts.corporateClient.industryOption.electricVehicles',
  'corporateAccounts.corporateClient.industryOption.electrical':
    'corporateAccounts.corporateClient.industryOption.electrical',
  'corporateAccounts.corporateClient.industryOption.engineering':
    'corporateAccounts.corporateClient.industryOption.engineering',
  'corporateAccounts.corporateClient.industryOption.foodService':
    'corporateAccounts.corporateClient.industryOption.foodService',
  'corporateAccounts.corporateClient.industryOption.furniture':
    'corporateAccounts.corporateClient.industryOption.furniture',
  'corporateAccounts.corporateClient.industryOption.government':
    'corporateAccounts.corporateClient.industryOption.government',
  'corporateAccounts.corporateClient.industryOption.healthcare':
    'corporateAccounts.corporateClient.industryOption.healthcare',
  'corporateAccounts.corporateClient.industryOption.higherEducation':
    'corporateAccounts.corporateClient.industryOption.higherEducation',
  'corporateAccounts.corporateClient.industryOption.hospitals':
    'corporateAccounts.corporateClient.industryOption.hospitals',
  'corporateAccounts.corporateClient.industryOption.hotelChain':
    'corporateAccounts.corporateClient.industryOption.hotelChain',
  'corporateAccounts.corporateClient.industryOption.insurance':
    'corporateAccounts.corporateClient.industryOption.insurance',
  'corporateAccounts.corporateClient.industryOption.landscape':
    'corporateAccounts.corporateClient.industryOption.landscape',
  'corporateAccounts.corporateClient.industryOption.manufacturing':
    'corporateAccounts.corporateClient.industryOption.manufacturing',
  'corporateAccounts.corporateClient.industryOption.mobileHealth':
    'corporateAccounts.corporateClient.industryOption.mobileHealth',
  'corporateAccounts.corporateClient.industryOption.mobileSalesTeam':
    'corporateAccounts.corporateClient.industryOption.mobileSalesTeam',
  'corporateAccounts.corporateClient.industryOption.municipal':
    'corporateAccounts.corporateClient.industryOption.municipal',
  'corporateAccounts.corporateClient.industryOption.officeParking':
    'corporateAccounts.corporateClient.industryOption.officeParking',
  'corporateAccounts.corporateClient.industryOption.other':
    'corporateAccounts.corporateClient.industryOption.other',
  'corporateAccounts.corporateClient.industryOption.passengerTerminal':
    'corporateAccounts.corporateClient.industryOption.passengerTerminal',
  'corporateAccounts.corporateClient.industryOption.printing':
    'corporateAccounts.corporateClient.industryOption.printing',
  'corporateAccounts.corporateClient.industryOption.realEstate':
    'corporateAccounts.corporateClient.industryOption.realEstate',
  'corporateAccounts.corporateClient.industryOption.retail':
    'corporateAccounts.corporateClient.industryOption.retail',
  'corporateAccounts.corporateClient.industryOption.specialtyTrade':
    'corporateAccounts.corporateClient.industryOption.specialtyTrade',
  'corporateAccounts.corporateClient.industryOption.sport':
    'corporateAccounts.corporateClient.industryOption.sport',
  'corporateAccounts.corporateClient.industryOption.telecommunications':
    'corporateAccounts.corporateClient.industryOption.telecommunications',
  'corporateAccounts.corporateClient.industryOption.tourism':
    'corporateAccounts.corporateClient.industryOption.tourism',
  'corporateAccounts.corporateClient.industryOption.utilities':
    'corporateAccounts.corporateClient.industryOption.utilities',
  'corporateAccounts.corporateClient.name': 'corporateAccounts.corporateClient.name',
  'corporateAccounts.corporateClient.name.error.presence':
    'corporateAccounts.corporateClient.name.error.presence',
  'corporateAccounts.corporateClient.officeNumber':
    'corporateAccounts.corporateClient.officeNumber',
  'corporateAccounts.corporateClient.owner.firstName.error.presence':
    'corporateAccounts.corporateClient.owner.firstName.error.presence',
  'corporateAccounts.corporateClient.parkingPaymentMethod':
    'corporateAccounts.corporateClient.parkingPaymentMethod',
  'corporateAccounts.corporateClient.payment.accountOwnerEmail':
    'corporateAccounts.corporateClient.payment.accountOwnerEmail',
  'corporateAccounts.corporateClient.payment.automaticPayment':
    'corporateAccounts.corporateClient.payment.automaticPayment',
  'corporateAccounts.corporateClient.payment.cardExpiry':
    'corporateAccounts.corporateClient.payment.cardExpiry',
  'corporateAccounts.corporateClient.payment.error.valueType':
    'corporateAccounts.corporateClient.payment.error.valueType',
  'corporateAccounts.corporateClient.payment.expiryMonth.error.invalid':
    'corporateAccounts.corporateClient.payment.expiryMonth.error.invalid',
  'corporateAccounts.corporateClient.payment.expiryMonth.error.presence':
    'corporateAccounts.corporateClient.payment.expiryMonth.error.presence',
  'corporateAccounts.corporateClient.payment.expiryYear.error':
    'corporateAccounts.corporateClient.payment.expiryYear.error',
  'corporateAccounts.corporateClient.payment.expiryYear.error.presence':
    'corporateAccounts.corporateClient.payment.expiryYear.error.presence',
  'corporateAccounts.corporateClient.payment.firstSixdigits':
    'corporateAccounts.corporateClient.payment.firstSixdigits',
  'corporateAccounts.corporateClient.payment.firstSixdigits.error.length':
    'corporateAccounts.corporateClient.payment.firstSixdigits.error.length',
  'corporateAccounts.corporateClient.payment.firstSixdigits.error.presence':
    'corporateAccounts.corporateClient.payment.firstSixdigits.error.presence',
  'corporateAccounts.corporateClient.payment.invoiceSent':
    'corporateAccounts.corporateClient.payment.invoiceSent',
  'corporateAccounts.corporateClient.payment.invoicedManually':
    'corporateAccounts.corporateClient.payment.invoicedManually',
  'corporateAccounts.corporateClient.payment.lastFourDigits':
    'corporateAccounts.corporateClient.payment.lastFourDigits',
  'corporateAccounts.corporateClient.payment.lastFourDigits.error.length':
    'corporateAccounts.corporateClient.payment.lastFourDigits.error.length',
  'corporateAccounts.corporateClient.payment.lastFourDigits.error.presence':
    'corporateAccounts.corporateClient.payment.lastFourDigits.error.presence',
  'corporateAccounts.corporateClient.payment.noInviteSent':
    'corporateAccounts.corporateClient.payment.noInviteSent',
  'corporateAccounts.corporateClient.payment.paymentInfo':
    'corporateAccounts.corporateClient.payment.paymentInfo',
  'corporateAccounts.corporateClient.payment.subscriptionCommitted':
    'corporateAccounts.corporateClient.payment.subscriptionCommitted',
  'corporateAccounts.corporateClient.postalCode': 'corporateAccounts.corporateClient.postalCode',
  'corporateAccounts.corporateClient.postalCode.error.presence':
    'corporateAccounts.corporateClient.postalCode.error.presence',
  'corporateAccounts.corporateClient.postalCode.error.validity':
    'corporateAccounts.corporateClient.postalCode.error.validity',
  'corporateAccounts.corporateClient.preferredLanguage':
    'corporateAccounts.corporateClient.preferredLanguage',
  'corporateAccounts.corporateClient.preferredLanguage.error.presence':
    'corporateAccounts.corporateClient.preferredLanguage.error.presence',
  'corporateAccounts.corporateClient.preferredLanguage.placeholder':
    'corporateAccounts.corporateClient.preferredLanguage.placeholder',
  'corporateAccounts.corporateClient.region': 'corporateAccounts.corporateClient.region',
  'corporateAccounts.corporateClient.region.error.presence':
    'corporateAccounts.corporateClient.region.error.presence',
  'corporateAccounts.corporateClient.region.placeholder':
    'corporateAccounts.corporateClient.region.placeholder',
  'corporateAccounts.corporateClient.register.addressTitle':
    'corporateAccounts.corporateClient.register.addressTitle',
  'corporateAccounts.corporateClient.register.duplicateNameError.message':
    'corporateAccounts.corporateClient.register.duplicateNameError.message',
  'corporateAccounts.corporateClient.register.duplicateNameError.title':
    'corporateAccounts.corporateClient.register.duplicateNameError.title',
  'corporateAccounts.corporateClient.register.internalError.message':
    'corporateAccounts.corporateClient.register.internalError.message',
  'corporateAccounts.corporateClient.register.internalError.title':
    'corporateAccounts.corporateClient.register.internalError.title',
  'corporateAccounts.corporateClient.register.registrationInfo':
    'corporateAccounts.corporateClient.register.registrationInfo',
  'corporateAccounts.corporateClient.registrationType':
    'corporateAccounts.corporateClient.registrationType',
  'corporateAccounts.corporateClient.registrationType.enterprise':
    'corporateAccounts.corporateClient.registrationType.enterprise',
  'corporateAccounts.corporateClient.registrationType.selfRegistered':
    'corporateAccounts.corporateClient.registrationType.selfRegistered',
  'corporateAccounts.corporateClient.restrictionType':
    'corporateAccounts.corporateClient.restrictionType',
  'corporateAccounts.corporateClient.restrictionType.restricted':
    'corporateAccounts.corporateClient.restrictionType.restricted',
  'corporateAccounts.corporateClient.restrictionType.unrestricted':
    'corporateAccounts.corporateClient.restrictionType.unrestricted',
  'corporateAccounts.corporateClient.salesforceId':
    'corporateAccounts.corporateClient.salesforceId',
  'corporateAccounts.corporateClient.salesforceId.error.alphanumeric':
    'corporateAccounts.corporateClient.salesforceId.error.alphanumeric',
  'corporateAccounts.corporateClient.salesforceId.error.invalid':
    'corporateAccounts.corporateClient.salesforceId.error.invalid',
  'corporateAccounts.corporateClient.salesforceId.error.length':
    'corporateAccounts.corporateClient.salesforceId.error.length',
  'corporateAccounts.corporateClient.salesforceId.error.presence':
    'corporateAccounts.corporateClient.salesforceId.error.presence',
  'corporateAccounts.corporateClient.salesforceId.infoMessage':
    'corporateAccounts.corporateClient.salesforceId.infoMessage',
  'corporateAccounts.corporateClient.siretNo': 'corporateAccounts.corporateClient.siretNo',
  'corporateAccounts.corporateClient.stateProvince':
    'corporateAccounts.corporateClient.stateProvince',
  'corporateAccounts.corporateClient.streetAddress':
    'corporateAccounts.corporateClient.streetAddress',
  'corporateAccounts.corporateClient.taxNumber': 'corporateAccounts.corporateClient.taxNumber',
  'corporateAccounts.corporateClient.taxNumber.error.characterCountExceeded':
    'corporateAccounts.corporateClient.taxNumber.error.characterCountExceeded',
  'corporateAccounts.corporateClient.taxNumber.error.notAlphanumeric':
    'corporateAccounts.corporateClient.taxNumber.error.notAlphanumeric',
  'corporateAccounts.corporateClient.taxNumber.error.presence':
    'corporateAccounts.corporateClient.taxNumber.error.presence',
  'corporateAccounts.corporateClient.taxNumber.error_presence':
    'corporateAccounts.corporateClient.taxNumber.error_presence',
  'corporateAccounts.corporateClient.version': 'corporateAccounts.corporateClient.version',
  'corporateAccounts.corporations.register.addressTitle':
    'corporateAccounts.corporations.register.addressTitle',
  'corporateAccounts.corporations.register.intro.message1':
    'corporateAccounts.corporations.register.intro.message1',
  'corporateAccounts.corporations.register.intro.message2':
    'corporateAccounts.corporations.register.intro.message2',
  'corporateAccounts.corporations.register.paymentMethod':
    'corporateAccounts.corporations.register.paymentMethod',
  'corporateAccounts.corporations.register.subtitle':
    'corporateAccounts.corporations.register.subtitle',
  'corporateAccounts.corporations.register.title': 'corporateAccounts.corporations.register.title',
  'corporateAccounts.corporations.title': 'corporateAccounts.corporations.title',
  'corporateAccounts.countries.ad': 'corporateAccounts.countries.ad',
  'corporateAccounts.countries.al': 'corporateAccounts.countries.al',
  'corporateAccounts.countries.am': 'corporateAccounts.countries.am',
  'corporateAccounts.countries.at': 'corporateAccounts.countries.at',
  'corporateAccounts.countries.au': 'corporateAccounts.countries.au',
  'corporateAccounts.countries.ba': 'corporateAccounts.countries.ba',
  'corporateAccounts.countries.be': 'corporateAccounts.countries.be',
  'corporateAccounts.countries.bg': 'corporateAccounts.countries.bg',
  'corporateAccounts.countries.by': 'corporateAccounts.countries.by',
  'corporateAccounts.countries.ca': 'corporateAccounts.countries.ca',
  'corporateAccounts.countries.ch': 'corporateAccounts.countries.ch',
  'corporateAccounts.countries.cy': 'corporateAccounts.countries.cy',
  'corporateAccounts.countries.cz': 'corporateAccounts.countries.cz',
  'corporateAccounts.countries.de': 'corporateAccounts.countries.de',
  'corporateAccounts.countries.dk': 'corporateAccounts.countries.dk',
  'corporateAccounts.countries.ee': 'corporateAccounts.countries.ee',
  'corporateAccounts.countries.es': 'corporateAccounts.countries.es',
  'corporateAccounts.countries.fi': 'corporateAccounts.countries.fi',
  'corporateAccounts.countries.fr': 'corporateAccounts.countries.fr',
  'corporateAccounts.countries.gb': 'corporateAccounts.countries.gb',
  'corporateAccounts.countries.gr': 'corporateAccounts.countries.gr',
  'corporateAccounts.countries.hr': 'corporateAccounts.countries.hr',
  'corporateAccounts.countries.hu': 'corporateAccounts.countries.hu',
  'corporateAccounts.countries.i': 'corporateAccounts.countries.i',
  'corporateAccounts.countries.ie': 'corporateAccounts.countries.ie',
  'corporateAccounts.countries.is': 'corporateAccounts.countries.is',
  'corporateAccounts.countries.it': 'corporateAccounts.countries.it',
  'corporateAccounts.countries.li': 'corporateAccounts.countries.li',
  'corporateAccounts.countries.lt': 'corporateAccounts.countries.lt',
  'corporateAccounts.countries.lu': 'corporateAccounts.countries.lu',
  'corporateAccounts.countries.lv': 'corporateAccounts.countries.lv',
  'corporateAccounts.countries.mc': 'corporateAccounts.countries.mc',
  'corporateAccounts.countries.md': 'corporateAccounts.countries.md',
  'corporateAccounts.countries.me': 'corporateAccounts.countries.me',
  'corporateAccounts.countries.mk': 'corporateAccounts.countries.mk',
  'corporateAccounts.countries.mt': 'corporateAccounts.countries.mt',
  'corporateAccounts.countries.nl': 'corporateAccounts.countries.nl',
  'corporateAccounts.countries.no': 'corporateAccounts.countries.no',
  'corporateAccounts.countries.pl': 'corporateAccounts.countries.pl',
  'corporateAccounts.countries.pt': 'corporateAccounts.countries.pt',
  'corporateAccounts.countries.ro': 'corporateAccounts.countries.ro',
  'corporateAccounts.countries.rs': 'corporateAccounts.countries.rs',
  'corporateAccounts.countries.ru': 'corporateAccounts.countries.ru',
  'corporateAccounts.countries.se': 'corporateAccounts.countries.se',
  'corporateAccounts.countries.sk': 'corporateAccounts.countries.sk',
  'corporateAccounts.countries.sm': 'corporateAccounts.countries.sm',
  'corporateAccounts.countries.tr': 'corporateAccounts.countries.tr',
  'corporateAccounts.countries.ua': 'corporateAccounts.countries.ua',
  'corporateAccounts.countries.us': 'corporateAccounts.countries.us',
  'corporateAccounts.countries.va': 'corporateAccounts.countries.va',
  'corporateAccounts.creditAccount.authorizedDriversBlurb':
    'corporateAccounts.creditAccount.authorizedDriversBlurb',
  'corporateAccounts.creditAccount.details.accountNumber':
    'corporateAccounts.creditAccount.details.accountNumber',
  'corporateAccounts.creditAccount.details.approvedCreditLimit':
    'corporateAccounts.creditAccount.details.approvedCreditLimit',
  'corporateAccounts.creditAccount.details.availableCredit':
    'corporateAccounts.creditAccount.details.availableCredit',
  'corporateAccounts.creditAccount.details.creditAccountDetails':
    'corporateAccounts.creditAccount.details.creditAccountDetails',
  'corporateAccounts.creditAccount.details.creditAccountId':
    'corporateAccounts.creditAccount.details.creditAccountId',
  'corporateAccounts.creditAccount.details.currentSpend':
    'corporateAccounts.creditAccount.details.currentSpend',
  'corporateAccounts.creditAccount.details.expiryDate':
    'corporateAccounts.creditAccount.details.expiryDate',
  'corporateAccounts.creditAccount.details.invoicingFrequency':
    'corporateAccounts.creditAccount.details.invoicingFrequency',
  'corporateAccounts.creditAccount.details.lastUpdated':
    'corporateAccounts.creditAccount.details.lastUpdated',
  'corporateAccounts.creditAccount.details.nextPaymentDate':
    'corporateAccounts.creditAccount.details.nextPaymentDate',
  'corporateAccounts.creditAccount.details.overLimitPaymentMethod':
    'corporateAccounts.creditAccount.details.overLimitPaymentMethod',
  'corporateAccounts.creditAccount.details.paymentAmountDue':
    'corporateAccounts.creditAccount.details.paymentAmountDue',
  'corporateAccounts.creditAccount.details.paymentDetails':
    'corporateAccounts.creditAccount.details.paymentDetails',
  'corporateAccounts.creditAccount.details.paymentMethod':
    'corporateAccounts.creditAccount.details.paymentMethod',
  'corporateAccounts.creditAccount.details.toolTip':
    'corporateAccounts.creditAccount.details.toolTip',
  'corporateAccounts.creditAccount.internalError': 'corporateAccounts.creditAccount.internalError',
  'corporateAccounts.creditAccount.migrationStatus':
    'corporateAccounts.creditAccount.migrationStatus',
  'corporateAccounts.creditAccount.migrationStatus.new':
    'corporateAccounts.creditAccount.migrationStatus.new',
  'corporateAccounts.creditAccount.migrationStatus.notStarted':
    'corporateAccounts.creditAccount.migrationStatus.notStarted',
  'corporateAccounts.creditAccount.migrationStatus.started':
    'corporateAccounts.creditAccount.migrationStatus.started',
  'corporateAccounts.creditAccount.pageTitle': 'corporateAccounts.creditAccount.pageTitle',
  'corporateAccounts.creditAccount.pendingBanner.description':
    'corporateAccounts.creditAccount.pendingBanner.description',
  'corporateAccounts.creditAccount.redirectModal.description':
    'corporateAccounts.creditAccount.redirectModal.description',
  'corporateAccounts.creditAccount.redirectModal.title':
    'corporateAccounts.creditAccount.redirectModal.title',
  'corporateAccounts.creditAccount.rejectedBanner.cta':
    'corporateAccounts.creditAccount.rejectedBanner.cta',
  'corporateAccounts.creditAccount.rejectedBanner.description':
    'corporateAccounts.creditAccount.rejectedBanner.description',
  'corporateAccounts.creditAccount.rejectedBanner.title':
    'corporateAccounts.creditAccount.rejectedBanner.title',
  'corporateAccounts.creditAccount.seeFaq': 'corporateAccounts.creditAccount.seeFaq',
  'corporateAccounts.creditAccount.setUpButton': 'corporateAccounts.creditAccount.setUpButton',
  'corporateAccounts.creditAccount.status': 'corporateAccounts.creditAccount.status',
  'corporateAccounts.creditAccount.stepCard1.subtitle':
    'corporateAccounts.creditAccount.stepCard1.subtitle',
  'corporateAccounts.creditAccount.stepCard1.title':
    'corporateAccounts.creditAccount.stepCard1.title',
  'corporateAccounts.creditAccount.stepCard2.subtitle':
    'corporateAccounts.creditAccount.stepCard2.subtitle',
  'corporateAccounts.creditAccount.stepCard2.title':
    'corporateAccounts.creditAccount.stepCard2.title',
  'corporateAccounts.creditAccount.stepCard3.subtitle':
    'corporateAccounts.creditAccount.stepCard3.subtitle',
  'corporateAccounts.creditAccount.stepCard3.title':
    'corporateAccounts.creditAccount.stepCard3.title',
  'corporateAccounts.creditAccount.subtitle': 'corporateAccounts.creditAccount.subtitle',
  'corporateAccounts.creditAccount.title': 'corporateAccounts.creditAccount.title',
  'corporateAccounts.creditAccount.type': 'corporateAccounts.creditAccount.type',
  'corporateAccounts.creditAccount.type.creditAccount':
    'corporateAccounts.creditAccount.type.creditAccount',
  'corporateAccounts.creditAccount.type.creditBuilder':
    'corporateAccounts.creditAccount.type.creditBuilder',
  'corporateAccounts.creditAccount.type.hybrid': 'corporateAccounts.creditAccount.type.hybrid',
  'corporateAccounts.creditAccountMigration.error':
    'corporateAccounts.creditAccountMigration.error',
  'corporateAccounts.creditAccountMigration.error.countryNotSupported':
    'corporateAccounts.creditAccountMigration.error.countryNotSupported',
  'corporateAccounts.creditAccountMigration.error.notFound':
    'corporateAccounts.creditAccountMigration.error.notFound',
  'corporateAccounts.creditAccountMigration.success':
    'corporateAccounts.creditAccountMigration.success',
  'corporateAccounts.creditAccounts.modal.cta': 'corporateAccounts.creditAccounts.modal.cta',
  'corporateAccounts.creditAccounts.modal.description1':
    'corporateAccounts.creditAccounts.modal.description1',
  'corporateAccounts.creditAccounts.modal.description2':
    'corporateAccounts.creditAccounts.modal.description2',
  'corporateAccounts.creditAccounts.modal.description3':
    'corporateAccounts.creditAccounts.modal.description3',
  'corporateAccounts.creditAccounts.modal.description4':
    'corporateAccounts.creditAccounts.modal.description4',
  'corporateAccounts.creditAccounts.modal.title': 'corporateAccounts.creditAccounts.modal.title',
  'corporateAccounts.daysOfWeek.0': 'corporateAccounts.daysOfWeek.0',
  'corporateAccounts.daysOfWeek.1': 'corporateAccounts.daysOfWeek.1',
  'corporateAccounts.daysOfWeek.2': 'corporateAccounts.daysOfWeek.2',
  'corporateAccounts.daysOfWeek.3': 'corporateAccounts.daysOfWeek.3',
  'corporateAccounts.daysOfWeek.4': 'corporateAccounts.daysOfWeek.4',
  'corporateAccounts.daysOfWeek.5': 'corporateAccounts.daysOfWeek.5',
  'corporateAccounts.daysOfWeek.6': 'corporateAccounts.daysOfWeek.6',
  'corporateAccounts.driver.activate.success': 'corporateAccounts.driver.activate.success',
  'corporateAccounts.driver.activate.warning': 'corporateAccounts.driver.activate.warning',
  'corporateAccounts.driver.add.error': 'corporateAccounts.driver.add.error',
  'corporateAccounts.driver.bulkDelete.success': 'corporateAccounts.driver.bulkDelete.success',
  'corporateAccounts.driver.delete.error': 'corporateAccounts.driver.delete.error',
  'corporateAccounts.driver.delete.success': 'corporateAccounts.driver.delete.success',
  'corporateAccounts.driver.generic.error': 'corporateAccounts.driver.generic.error',
  'corporateAccounts.driver.get.error': 'corporateAccounts.driver.get.error',
  'corporateAccounts.driver.invite.error': 'corporateAccounts.driver.invite.error',
  'corporateAccounts.driver.invite.success': 'corporateAccounts.driver.invite.success',
  'corporateAccounts.driver.limitExceeded.error': 'corporateAccounts.driver.limitExceeded.error',
  'corporateAccounts.driver.profileExists.error': 'corporateAccounts.driver.profileExists.error',
  'corporateAccounts.driver.update.error': 'corporateAccounts.driver.update.error',
  'corporateAccounts.driver.update.profileExistsError':
    'corporateAccounts.driver.update.profileExistsError',
  'corporateAccounts.driver.update.success': 'corporateAccounts.driver.update.success',
  'corporateAccounts.drivers.actions': 'corporateAccounts.drivers.actions',
  'corporateAccounts.drivers.actions.activate': 'corporateAccounts.drivers.actions.activate',
  'corporateAccounts.drivers.actions.addParker': 'corporateAccounts.drivers.actions.addParker',
  'corporateAccounts.drivers.actions.deleteParkerMessage':
    'corporateAccounts.drivers.actions.deleteParkerMessage',
  'corporateAccounts.drivers.actions.reinvite': 'corporateAccounts.drivers.actions.reinvite',
  'corporateAccounts.drivers.activationModal.body1':
    'corporateAccounts.drivers.activationModal.body1',
  'corporateAccounts.drivers.activationModal.body2':
    'corporateAccounts.drivers.activationModal.body2',
  'corporateAccounts.drivers.bulkDelete.modal.content':
    'corporateAccounts.drivers.bulkDelete.modal.content',
  'corporateAccounts.drivers.bulkDelete.modal.title':
    'corporateAccounts.drivers.bulkDelete.modal.title',
  'corporateAccounts.drivers.bulkResendInvite.sucess':
    'corporateAccounts.drivers.bulkResendInvite.sucess',
  'corporateAccounts.drivers.bulkResendInvite.warning':
    'corporateAccounts.drivers.bulkResendInvite.warning',
  'corporateAccounts.drivers.bulkResendInviteModal.description':
    'corporateAccounts.drivers.bulkResendInviteModal.description',
  'corporateAccounts.drivers.bulkResendInviteModal.title':
    'corporateAccounts.drivers.bulkResendInviteModal.title',
  'corporateAccounts.drivers.costCenter': 'corporateAccounts.drivers.costCenter',
  'corporateAccounts.drivers.costCenterInfo': 'corporateAccounts.drivers.costCenterInfo',
  'corporateAccounts.drivers.csvDownload.costCenter':
    'corporateAccounts.drivers.csvDownload.costCenter',
  'corporateAccounts.drivers.csvDownload.email': 'corporateAccounts.drivers.csvDownload.email',
  'corporateAccounts.drivers.csvDownload.phoneCountryCode':
    'corporateAccounts.drivers.csvDownload.phoneCountryCode',
  'corporateAccounts.drivers.csvDownload.phoneNumber':
    'corporateAccounts.drivers.csvDownload.phoneNumber',
  'corporateAccounts.drivers.deleteModal.content': 'corporateAccounts.drivers.deleteModal.content',
  'corporateAccounts.drivers.deleteModal.title': 'corporateAccounts.drivers.deleteModal.title',
  'corporateAccounts.drivers.department': 'corporateAccounts.drivers.department',
  'corporateAccounts.drivers.email': 'corporateAccounts.drivers.email',
  'corporateAccounts.drivers.employeeId': 'corporateAccounts.drivers.employeeId',
  'corporateAccounts.drivers.emptyState.description':
    'corporateAccounts.drivers.emptyState.description',
  'corporateAccounts.drivers.emptyState.importDriversBtn':
    'corporateAccounts.drivers.emptyState.importDriversBtn',
  'corporateAccounts.drivers.emptyState.videoLink':
    'corporateAccounts.drivers.emptyState.videoLink',
  'corporateAccounts.drivers.export.error.modal.description':
    'corporateAccounts.drivers.export.error.modal.description',
  'corporateAccounts.drivers.export.error.modal.title':
    'corporateAccounts.drivers.export.error.modal.title',
  'corporateAccounts.drivers.export.fileName': 'corporateAccounts.drivers.export.fileName',
  'corporateAccounts.drivers.export.modal.description':
    'corporateAccounts.drivers.export.modal.description',
  'corporateAccounts.drivers.export.modal.title': 'corporateAccounts.drivers.export.modal.title',
  'corporateAccounts.drivers.export.success.notification.description':
    'corporateAccounts.drivers.export.success.notification.description',
  'corporateAccounts.drivers.export.success.notification.title':
    'corporateAccounts.drivers.export.success.notification.title',
  'corporateAccounts.drivers.firstName': 'corporateAccounts.drivers.firstName',
  'corporateAccounts.drivers.form.add': 'corporateAccounts.drivers.form.add',
  'corporateAccounts.drivers.form.costCenterMaxLength':
    'corporateAccounts.drivers.form.costCenterMaxLength',
  'corporateAccounts.drivers.form.emailRequired': 'corporateAccounts.drivers.form.emailRequired',
  'corporateAccounts.drivers.form.emailValidation':
    'corporateAccounts.drivers.form.emailValidation',
  'corporateAccounts.drivers.form.employeeIdValidation':
    'corporateAccounts.drivers.form.employeeIdValidation',
  'corporateAccounts.drivers.form.firstNameValidation':
    'corporateAccounts.drivers.form.firstNameValidation',
  'corporateAccounts.drivers.form.invitationOptions':
    'corporateAccounts.drivers.form.invitationOptions',
  'corporateAccounts.drivers.form.inviteOptionsError':
    'corporateAccounts.drivers.form.inviteOptionsError',
  'corporateAccounts.drivers.form.jobTitleValidation':
    'corporateAccounts.drivers.form.jobTitleValidation',
  'corporateAccounts.drivers.form.lastNameValidation':
    'corporateAccounts.drivers.form.lastNameValidation',
  'corporateAccounts.drivers.form.phoneRequired': 'corporateAccounts.drivers.form.phoneRequired',
  'corporateAccounts.drivers.form.phoneValidation':
    'corporateAccounts.drivers.form.phoneValidation',
  'corporateAccounts.drivers.form.selectInvitationMethod':
    'corporateAccounts.drivers.form.selectInvitationMethod',
  'corporateAccounts.drivers.form.sms': 'corporateAccounts.drivers.form.sms',
  'corporateAccounts.drivers.form.title.edit': 'corporateAccounts.drivers.form.title.edit',
  'corporateAccounts.drivers.form.title.new': 'corporateAccounts.drivers.form.title.new',
  'corporateAccounts.drivers.form.update': 'corporateAccounts.drivers.form.update',
  'corporateAccounts.drivers.groupId': 'corporateAccounts.drivers.groupId',
  'corporateAccounts.drivers.import.error': 'corporateAccounts.drivers.import.error',
  'corporateAccounts.drivers.import.error.EmailRequired':
    'corporateAccounts.drivers.import.error.EmailRequired',
  'corporateAccounts.drivers.import.error.UnknownParsingError':
    'corporateAccounts.drivers.import.error.UnknownParsingError',
  'corporateAccounts.drivers.import.error.costCenterMaxLengthExceeded':
    'corporateAccounts.drivers.import.error.costCenterMaxLengthExceeded',
  'corporateAccounts.drivers.import.error.csvNoRecord':
    'corporateAccounts.drivers.import.error.csvNoRecord',
  'corporateAccounts.drivers.import.error.duplicateEntries':
    'corporateAccounts.drivers.import.error.duplicateEntries',
  'corporateAccounts.drivers.import.error.failed': 'corporateAccounts.drivers.import.error.failed',
  'corporateAccounts.drivers.import.error.fileName':
    'corporateAccounts.drivers.import.error.fileName',
  'corporateAccounts.drivers.import.error.firstNameMaxLengthExceeded':
    'corporateAccounts.drivers.import.error.firstNameMaxLengthExceeded',
  'corporateAccounts.drivers.import.error.firstNameRequired':
    'corporateAccounts.drivers.import.error.firstNameRequired',
  'corporateAccounts.drivers.import.error.invalidCostCenter':
    'corporateAccounts.drivers.import.error.invalidCostCenter',
  'corporateAccounts.drivers.import.error.invalidEmail':
    'corporateAccounts.drivers.import.error.invalidEmail',
  'corporateAccounts.drivers.import.error.invalidEntries':
    'corporateAccounts.drivers.import.error.invalidEntries',
  'corporateAccounts.drivers.import.error.invalidNumbers':
    'corporateAccounts.drivers.import.error.invalidNumbers',
  'corporateAccounts.drivers.import.error.lastNameMaxLengthExceeded':
    'corporateAccounts.drivers.import.error.lastNameMaxLengthExceeded',
  'corporateAccounts.drivers.import.error.lastNameRequired':
    'corporateAccounts.drivers.import.error.lastNameRequired',
  'corporateAccounts.drivers.import.error.phoneNumberRequired':
    'corporateAccounts.drivers.import.error.phoneNumberRequired',
  'corporateAccounts.drivers.import.error.uploadFile':
    'corporateAccounts.drivers.import.error.uploadFile',
  'corporateAccounts.drivers.import.failed': 'corporateAccounts.drivers.import.failed',
  'corporateAccounts.drivers.import.fileName': 'corporateAccounts.drivers.import.fileName',
  'corporateAccounts.drivers.import.header.costCenter':
    'corporateAccounts.drivers.import.header.costCenter',
  'corporateAccounts.drivers.import.header.countryCode':
    'corporateAccounts.drivers.import.header.countryCode',
  'corporateAccounts.drivers.import.header.email': 'corporateAccounts.drivers.import.header.email',
  'corporateAccounts.drivers.import.header.phoneNumber':
    'corporateAccounts.drivers.import.header.phoneNumber',
  'corporateAccounts.drivers.import.inProgress': 'corporateAccounts.drivers.import.inProgress',
  'corporateAccounts.drivers.import.spaModal.title':
    'corporateAccounts.drivers.import.spaModal.title',
  'corporateAccounts.drivers.import.success': 'corporateAccounts.drivers.import.success',
  'corporateAccounts.drivers.import.warning': 'corporateAccounts.drivers.import.warning',
  'corporateAccounts.drivers.importModal.editDrivers':
    'corporateAccounts.drivers.importModal.editDrivers',
  'corporateAccounts.drivers.importModal.editDrivers.1':
    'corporateAccounts.drivers.importModal.editDrivers.1',
  'corporateAccounts.drivers.importModal.editDrivers.2':
    'corporateAccounts.drivers.importModal.editDrivers.2',
  'corporateAccounts.drivers.importModal.editDrivers.3':
    'corporateAccounts.drivers.importModal.editDrivers.3',
  'corporateAccounts.drivers.importModal.newDrivers':
    'corporateAccounts.drivers.importModal.newDrivers',
  'corporateAccounts.drivers.importModal.newDrivers.1':
    'corporateAccounts.drivers.importModal.newDrivers.1',
  'corporateAccounts.drivers.importModal.title': 'corporateAccounts.drivers.importModal.title',
  'corporateAccounts.drivers.infograph.card.description':
    'corporateAccounts.drivers.infograph.card.description',
  'corporateAccounts.drivers.infograph.card.title':
    'corporateAccounts.drivers.infograph.card.title',
  'corporateAccounts.drivers.infograph.cards.0.body':
    'corporateAccounts.drivers.infograph.cards.0.body',
  'corporateAccounts.drivers.infograph.cards.0.title':
    'corporateAccounts.drivers.infograph.cards.0.title',
  'corporateAccounts.drivers.infograph.cards.1.title':
    'corporateAccounts.drivers.infograph.cards.1.title',
  'corporateAccounts.drivers.infograph.cards.2.body':
    'corporateAccounts.drivers.infograph.cards.2.body',
  'corporateAccounts.drivers.infograph.cards.2.title':
    'corporateAccounts.drivers.infograph.cards.2.title',
  'corporateAccounts.drivers.infograph.subcontent':
    'corporateAccounts.drivers.infograph.subcontent',
  'corporateAccounts.drivers.inviteDriverToolTip': 'corporateAccounts.drivers.inviteDriverToolTip',
  'corporateAccounts.drivers.jobTitle': 'corporateAccounts.drivers.jobTitle',
  'corporateAccounts.drivers.lastName': 'corporateAccounts.drivers.lastName',
  'corporateAccounts.drivers.main.title': 'corporateAccounts.drivers.main.title',
  'corporateAccounts.drivers.mobilePhoneNumber': 'corporateAccounts.drivers.mobilePhoneNumber',
  'corporateAccounts.drivers.resendInviteModal.description':
    'corporateAccounts.drivers.resendInviteModal.description',
  'corporateAccounts.drivers.restrictedAction.add.content':
    'corporateAccounts.drivers.restrictedAction.add.content',
  'corporateAccounts.drivers.restrictedAction.add.title':
    'corporateAccounts.drivers.restrictedAction.add.title',
  'corporateAccounts.drivers.restrictedAction.edit.content':
    'corporateAccounts.drivers.restrictedAction.edit.content',
  'corporateAccounts.drivers.restrictedAction.edit.title':
    'corporateAccounts.drivers.restrictedAction.edit.title',
  'corporateAccounts.drivers.restrictedAction.upload.title':
    'corporateAccounts.drivers.restrictedAction.upload.title',
  'corporateAccounts.drivers.search.placeholder': 'corporateAccounts.drivers.search.placeholder',
  'corporateAccounts.drivers.workPhoneNumber': 'corporateAccounts.drivers.workPhoneNumber',
  'corporateAccounts.errors.fileUpload': 'corporateAccounts.errors.fileUpload',
  'corporateAccounts.errors.fileUpload.driverImportLimitExceeded':
    'corporateAccounts.errors.fileUpload.driverImportLimitExceeded',
  'corporateAccounts.errors.fileUpload.restictedCorporateClient.driverImportLimitExceeded':
    'corporateAccounts.errors.fileUpload.restictedCorporateClient.driverImportLimitExceeded',
  'corporateAccounts.errors.unauthorized.content': 'corporateAccounts.errors.unauthorized.content',
  'corporateAccounts.errors.unauthorized.title': 'corporateAccounts.errors.unauthorized.title',
  'corporateAccounts.feedbackModal.button': 'corporateAccounts.feedbackModal.button',
  'corporateAccounts.feedbackModal.feedback.description':
    'corporateAccounts.feedbackModal.feedback.description',
  'corporateAccounts.feedbackModal.feedback.placeholder':
    'corporateAccounts.feedbackModal.feedback.placeholder',
  'corporateAccounts.feedbackModal.feedback.subDescription':
    'corporateAccounts.feedbackModal.feedback.subDescription',
  'corporateAccounts.feedbackModal.feedback.success':
    'corporateAccounts.feedbackModal.feedback.success',
  'corporateAccounts.feedbackModal.support.button':
    'corporateAccounts.feedbackModal.support.button',
  'corporateAccounts.feedbackModal.support.description':
    'corporateAccounts.feedbackModal.support.description',
  'corporateAccounts.feedbackModal.title': 'corporateAccounts.feedbackModal.title',
  'corporateAccounts.fileUpload.loading': 'corporateAccounts.fileUpload.loading',
  'corporateAccounts.globalNotification.maintenanceMessage':
    'corporateAccounts.globalNotification.maintenanceMessage',
  'corporateAccounts.globalNotification.maintenancePage.restoreTime':
    'corporateAccounts.globalNotification.maintenancePage.restoreTime',
  'corporateAccounts.globalNotification.maintenancePage.underGoingScheduledMaintenance':
    'corporateAccounts.globalNotification.maintenancePage.underGoingScheduledMaintenance',
  'corporateAccounts.globalNotification.maintenancePage.underScheduleMaintenance':
    'corporateAccounts.globalNotification.maintenancePage.underScheduleMaintenance',
  'corporateAccounts.homePage': 'corporateAccounts.homePage',
  'corporateAccounts.import.downloadCsv': 'corporateAccounts.import.downloadCsv',
  'corporateAccounts.import.downloadCsvTemplate': 'corporateAccounts.import.downloadCsvTemplate',
  'corporateAccounts.import.driver.success': 'corporateAccounts.import.driver.success',
  'corporateAccounts.import.emailNotify.success': 'corporateAccounts.import.emailNotify.success',
  'corporateAccounts.import.importData': 'corporateAccounts.import.importData',
  'corporateAccounts.import.maxFileSize.error': 'corporateAccounts.import.maxFileSize.error',
  'corporateAccounts.import.singleFile.error': 'corporateAccounts.import.singleFile.error',
  'corporateAccounts.import.step.1.title': 'corporateAccounts.import.step.1.title',
  'corporateAccounts.import.step.2.title': 'corporateAccounts.import.step.2.title',
  'corporateAccounts.import.step.3.title': 'corporateAccounts.import.step.3.title',
  'corporateAccounts.import.step.5.content': 'corporateAccounts.import.step.5.content',
  'corporateAccounts.import.title.success': 'corporateAccounts.import.title.success',
  'corporateAccounts.import.vehicle.success': 'corporateAccounts.import.vehicle.success',
  'corporateAccounts.infograph.drivers.cards.0.body':
    'corporateAccounts.infograph.drivers.cards.0.body',
  'corporateAccounts.infograph.drivers.cards.0.title':
    'corporateAccounts.infograph.drivers.cards.0.title',
  'corporateAccounts.infograph.drivers.cards.1.body':
    'corporateAccounts.infograph.drivers.cards.1.body',
  'corporateAccounts.infograph.drivers.cards.1.title':
    'corporateAccounts.infograph.drivers.cards.1.title',
  'corporateAccounts.infograph.drivers.cards.2.body':
    'corporateAccounts.infograph.drivers.cards.2.body',
  'corporateAccounts.infograph.drivers.cards.2.title':
    'corporateAccounts.infograph.drivers.cards.2.title',
  'corporateAccounts.infograph.drivers.note': 'corporateAccounts.infograph.drivers.note',
  'corporateAccounts.infograph.payments.cards.0.body':
    'corporateAccounts.infograph.payments.cards.0.body',
  'corporateAccounts.infograph.payments.cards.0.title':
    'corporateAccounts.infograph.payments.cards.0.title',
  'corporateAccounts.infograph.payments.cards.1.body':
    'corporateAccounts.infograph.payments.cards.1.body',
  'corporateAccounts.infograph.payments.cards.1.title':
    'corporateAccounts.infograph.payments.cards.1.title',
  'corporateAccounts.infograph.payments.cards.2.body':
    'corporateAccounts.infograph.payments.cards.2.body',
  'corporateAccounts.infograph.payments.cards.2.title':
    'corporateAccounts.infograph.payments.cards.2.title',
  'corporateAccounts.infograph.payments.note': 'corporateAccounts.infograph.payments.note',
  'corporateAccounts.infograph.vehicles.cards.0.body':
    'corporateAccounts.infograph.vehicles.cards.0.body',
  'corporateAccounts.infograph.vehicles.cards.0.title':
    'corporateAccounts.infograph.vehicles.cards.0.title',
  'corporateAccounts.infograph.vehicles.cards.1.body':
    'corporateAccounts.infograph.vehicles.cards.1.body',
  'corporateAccounts.infograph.vehicles.cards.1.title':
    'corporateAccounts.infograph.vehicles.cards.1.title',
  'corporateAccounts.infograph.vehicles.cards.2.body':
    'corporateAccounts.infograph.vehicles.cards.2.body',
  'corporateAccounts.infograph.vehicles.cards.2.title':
    'corporateAccounts.infograph.vehicles.cards.2.title',
  'corporateAccounts.infograph.vehicles.note': 'corporateAccounts.infograph.vehicles.note',
  'corporateAccounts.invoiceIdValidation.invalidError':
    'corporateAccounts.invoiceIdValidation.invalidError',
  'corporateAccounts.invoicesAndStatements.billingDate':
    'corporateAccounts.invoicesAndStatements.billingDate',
  'corporateAccounts.invoicesAndStatements.downloadInvoice':
    'corporateAccounts.invoicesAndStatements.downloadInvoice',
  'corporateAccounts.invoicesAndStatements.error': 'corporateAccounts.invoicesAndStatements.error',
  'corporateAccounts.invoicesAndStatements.error.title':
    'corporateAccounts.invoicesAndStatements.error.title',
  'corporateAccounts.invoicesAndStatements.invoiceStatus':
    'corporateAccounts.invoicesAndStatements.invoiceStatus',
  'corporateAccounts.invoicesAndStatements.modal.description':
    'corporateAccounts.invoicesAndStatements.modal.description',
  'corporateAccounts.invoicesAndStatements.modal.error':
    'corporateAccounts.invoicesAndStatements.modal.error',
  'corporateAccounts.invoicesAndStatements.modal.invoice':
    'corporateAccounts.invoicesAndStatements.modal.invoice',
  'corporateAccounts.invoicesAndStatements.modal.noDownload':
    'corporateAccounts.invoicesAndStatements.modal.noDownload',
  'corporateAccounts.invoicesAndStatements.modal.success':
    'corporateAccounts.invoicesAndStatements.modal.success',
  'corporateAccounts.invoicesAndStatements.modal.summary':
    'corporateAccounts.invoicesAndStatements.modal.summary',
  'corporateAccounts.invoicesAndStatements.openAmount':
    'corporateAccounts.invoicesAndStatements.openAmount',
  'corporateAccounts.invoicesAndStatements.paymentDueDate':
    'corporateAccounts.invoicesAndStatements.paymentDueDate',
  'corporateAccounts.invoicesAndStatements.status.overdue':
    'corporateAccounts.invoicesAndStatements.status.overdue',
  'corporateAccounts.invoicesAndStatements.status.paid':
    'corporateAccounts.invoicesAndStatements.status.paid',
  'corporateAccounts.invoicesAndStatements.status.unpaid':
    'corporateAccounts.invoicesAndStatements.status.unpaid',
  'corporateAccounts.invoicesAndStatements.totalAmount':
    'corporateAccounts.invoicesAndStatements.totalAmount',
  'corporateAccounts.languages.de': 'corporateAccounts.languages.de',
  'corporateAccounts.languages.fr': 'corporateAccounts.languages.fr',
  'corporateAccounts.languages.gb': 'corporateAccounts.languages.gb',
  'corporateAccounts.languages.glb': 'corporateAccounts.languages.glb',
  'corporateAccounts.languages.it': 'corporateAccounts.languages.it',
  'corporateAccounts.languages.nl': 'corporateAccounts.languages.nl',
  'corporateAccounts.languages.us': 'corporateAccounts.languages.us',
  'corporateAccounts.menuLeft.account': 'corporateAccounts.menuLeft.account',
  'corporateAccounts.menuLeft.accounts': 'corporateAccounts.menuLeft.accounts',
  'corporateAccounts.menuLeft.adminUsers': 'corporateAccounts.menuLeft.adminUsers',
  'corporateAccounts.menuLeft.admins': 'corporateAccounts.menuLeft.admins',
  'corporateAccounts.menuLeft.auditLogs': 'corporateAccounts.menuLeft.auditLogs',
  'corporateAccounts.menuLeft.billing': 'corporateAccounts.menuLeft.billing',
  'corporateAccounts.menuLeft.creditAccount': 'corporateAccounts.menuLeft.creditAccount',
  'corporateAccounts.menuLeft.drivers': 'corporateAccounts.menuLeft.drivers',
  'corporateAccounts.menuLeft.invoicesAndStatements':
    'corporateAccounts.menuLeft.invoicesAndStatements',
  'corporateAccounts.menuLeft.monthlyStatements': 'corporateAccounts.menuLeft.monthlyStatements',
  'corporateAccounts.menuLeft.payments': 'corporateAccounts.menuLeft.payments',
  'corporateAccounts.menuLeft.reports': 'corporateAccounts.menuLeft.reports',
  'corporateAccounts.menuLeft.sessions': 'corporateAccounts.menuLeft.sessions',
  'corporateAccounts.menuLeft.staffUsers': 'corporateAccounts.menuLeft.staffUsers',
  'corporateAccounts.menuLeft.superAdminHome': 'corporateAccounts.menuLeft.superAdminHome',
  'corporateAccounts.menuLeft.trigger': 'corporateAccounts.menuLeft.trigger',
  'corporateAccounts.menuLeft.vehicles': 'corporateAccounts.menuLeft.vehicles',
  'corporateAccounts.month.1': 'corporateAccounts.month.1',
  'corporateAccounts.month.10': 'corporateAccounts.month.10',
  'corporateAccounts.month.11': 'corporateAccounts.month.11',
  'corporateAccounts.month.12': 'corporateAccounts.month.12',
  'corporateAccounts.month.2': 'corporateAccounts.month.2',
  'corporateAccounts.month.3': 'corporateAccounts.month.3',
  'corporateAccounts.month.4': 'corporateAccounts.month.4',
  'corporateAccounts.month.5': 'corporateAccounts.month.5',
  'corporateAccounts.month.6': 'corporateAccounts.month.6',
  'corporateAccounts.month.7': 'corporateAccounts.month.7',
  'corporateAccounts.month.8': 'corporateAccounts.month.8',
  'corporateAccounts.month.9': 'corporateAccounts.month.9',
  'corporateAccounts.monthlyStatements.downloadStatement':
    'corporateAccounts.monthlyStatements.downloadStatement',
  'corporateAccounts.monthlyStatements.generatedOn':
    'corporateAccounts.monthlyStatements.generatedOn',
  'corporateAccounts.monthlyStatements.title': 'corporateAccounts.monthlyStatements.title',
  'corporateAccounts.notFound': 'corporateAccounts.notFound',
  'corporateAccounts.notification.reportReady.description':
    'corporateAccounts.notification.reportReady.description',
  'corporateAccounts.notification.reportReady.title':
    'corporateAccounts.notification.reportReady.title',
  'corporateAccounts.notify.error.auditLogs': 'corporateAccounts.notify.error.auditLogs',
  'corporateAccounts.notify.error.downloadMonthlyStatement':
    'corporateAccounts.notify.error.downloadMonthlyStatement',
  'corporateAccounts.notify.error.getMonthlyStatements':
    'corporateAccounts.notify.error.getMonthlyStatements',
  'corporateAccounts.onboarding.PasswordComplexityNotMet':
    'corporateAccounts.onboarding.PasswordComplexityNotMet',
  'corporateAccounts.onboarding.actions.openPayByPhone':
    'corporateAccounts.onboarding.actions.openPayByPhone',
  'corporateAccounts.onboarding.activationFailed': 'corporateAccounts.onboarding.activationFailed',
  'corporateAccounts.onboarding.activationFailed.message':
    'corporateAccounts.onboarding.activationFailed.message',
  'corporateAccounts.onboarding.addDriverStep.description':
    'corporateAccounts.onboarding.addDriverStep.description',
  'corporateAccounts.onboarding.addDriverStep.title':
    'corporateAccounts.onboarding.addDriverStep.title',
  'corporateAccounts.onboarding.addVehicleStep.description':
    'corporateAccounts.onboarding.addVehicleStep.description',
  'corporateAccounts.onboarding.addVehicleStep.title':
    'corporateAccounts.onboarding.addVehicleStep.title',
  'corporateAccounts.onboarding.corporationNotFound':
    'corporateAccounts.onboarding.corporationNotFound',
  'corporateAccounts.onboarding.corporationNotFound.message':
    'corporateAccounts.onboarding.corporationNotFound.message',
  'corporateAccounts.onboarding.driverAlreadyActive':
    'corporateAccounts.onboarding.driverAlreadyActive',
  'corporateAccounts.onboarding.driverAlreadyActive.message':
    'corporateAccounts.onboarding.driverAlreadyActive.message',
  'corporateAccounts.onboarding.driverNotFound': 'corporateAccounts.onboarding.driverNotFound',
  'corporateAccounts.onboarding.driverNotFound.message':
    'corporateAccounts.onboarding.driverNotFound.message',
  'corporateAccounts.onboarding.internalError': 'corporateAccounts.onboarding.internalError',
  'corporateAccounts.onboarding.internalError.message':
    'corporateAccounts.onboarding.internalError.message',
  'corporateAccounts.onboarding.invitationExpired':
    'corporateAccounts.onboarding.invitationExpired',
  'corporateAccounts.onboarding.invitationExpired.message':
    'corporateAccounts.onboarding.invitationExpired.message',
  'corporateAccounts.onboarding.inviteCodeNotFound':
    'corporateAccounts.onboarding.inviteCodeNotFound',
  'corporateAccounts.onboarding.inviteCodeNotFound.message':
    'corporateAccounts.onboarding.inviteCodeNotFound.message',
  'corporateAccounts.onboarding.inviteCodeNotValid':
    'corporateAccounts.onboarding.inviteCodeNotValid',
  'corporateAccounts.onboarding.inviteCodeNotValid.message':
    'corporateAccounts.onboarding.inviteCodeNotValid.message',
  'corporateAccounts.onboarding.logInToStartParking':
    'corporateAccounts.onboarding.logInToStartParking',
  'corporateAccounts.onboarding.passwordComplexityNotMet.message':
    'corporateAccounts.onboarding.passwordComplexityNotMet.message',
  'corporateAccounts.onboarding.progressBar.description':
    'corporateAccounts.onboarding.progressBar.description',
  'corporateAccounts.onboarding.progressBar.title':
    'corporateAccounts.onboarding.progressBar.title',
  'corporateAccounts.onboarding.registration.formDescription':
    'corporateAccounts.onboarding.registration.formDescription',
  'corporateAccounts.onboarding.registration.formTitle':
    'corporateAccounts.onboarding.registration.formTitle',
  'corporateAccounts.onboarding.registrationRequired':
    'corporateAccounts.onboarding.registrationRequired',
  'corporateAccounts.onboarding.registrationRequired.message':
    'corporateAccounts.onboarding.registrationRequired.message',
  'corporateAccounts.onboarding.setupAccountStep.description':
    'corporateAccounts.onboarding.setupAccountStep.description',
  'corporateAccounts.onboarding.setupAccountStep.title':
    'corporateAccounts.onboarding.setupAccountStep.title',
  'corporateAccounts.onboarding.setupPaymentStep.description':
    'corporateAccounts.onboarding.setupPaymentStep.description',
  'corporateAccounts.onboarding.setupPaymentStep.title':
    'corporateAccounts.onboarding.setupPaymentStep.title',
  'corporateAccounts.onboarding.socialSignIn.instruction':
    'corporateAccounts.onboarding.socialSignIn.instruction',
  'corporateAccounts.onboarding.success': 'corporateAccounts.onboarding.success',
  'corporateAccounts.onboarding.success.carousel1.text':
    'corporateAccounts.onboarding.success.carousel1.text',
  'corporateAccounts.onboarding.success.carousel1.title':
    'corporateAccounts.onboarding.success.carousel1.title',
  'corporateAccounts.onboarding.success.carousel2.text':
    'corporateAccounts.onboarding.success.carousel2.text',
  'corporateAccounts.onboarding.success.carousel2.title':
    'corporateAccounts.onboarding.success.carousel2.title',
  'corporateAccounts.onboarding.success.carousel3.subText':
    'corporateAccounts.onboarding.success.carousel3.subText',
  'corporateAccounts.onboarding.success.carousel3.text':
    'corporateAccounts.onboarding.success.carousel3.text',
  'corporateAccounts.onboarding.success.carousel3.title':
    'corporateAccounts.onboarding.success.carousel3.title',
  'corporateAccounts.onboarding.success.message': 'corporateAccounts.onboarding.success.message',
  'corporateAccounts.onboarding.success.notification':
    'corporateAccounts.onboarding.success.notification',
  'corporateAccounts.onboarding.watchProductDemoVideo':
    'corporateAccounts.onboarding.watchProductDemoVideo',
  'corporateAccounts.onboarding.welcomeModal.actions.getStarted':
    'corporateAccounts.onboarding.welcomeModal.actions.getStarted',
  'corporateAccounts.onboarding.welcomeModal.description':
    'corporateAccounts.onboarding.welcomeModal.description',
  'corporateAccounts.onboarding.welcomeModal.frequentlyAskedQuestions':
    'corporateAccounts.onboarding.welcomeModal.frequentlyAskedQuestions',
  'corporateAccounts.onboarding.welcomeModal.title':
    'corporateAccounts.onboarding.welcomeModal.title',
  'corporateAccounts.pageDeprecated': 'corporateAccounts.pageDeprecated',
  'corporateAccounts.passwordValidation.defaultError':
    'corporateAccounts.passwordValidation.defaultError',
  'corporateAccounts.passwordValidation.lengthError':
    'corporateAccounts.passwordValidation.lengthError',
  'corporateAccounts.passwordValidation.lowerCaseError':
    'corporateAccounts.passwordValidation.lowerCaseError',
  'corporateAccounts.passwordValidation.missingNumberError':
    'corporateAccounts.passwordValidation.missingNumberError',
  'corporateAccounts.passwordValidation.missingSpecialCharacterError':
    'corporateAccounts.passwordValidation.missingSpecialCharacterError',
  'corporateAccounts.passwordValidation.upperCaseError':
    'corporateAccounts.passwordValidation.upperCaseError',
  'corporateAccounts.payByPhone': 'corporateAccounts.payByPhone',
  'corporateAccounts.payment.actions.restrictedEdit.content':
    'corporateAccounts.payment.actions.restrictedEdit.content',
  'corporateAccounts.payment.actions.restrictedEdit.title':
    'corporateAccounts.payment.actions.restrictedEdit.title',
  'corporateAccounts.paymentMethod.add.success': 'corporateAccounts.paymentMethod.add.success',
  'corporateAccounts.paymentMethod.delete.success':
    'corporateAccounts.paymentMethod.delete.success',
  'corporateAccounts.paymentMethod.infograph.cards.0.title':
    'corporateAccounts.paymentMethod.infograph.cards.0.title',
  'corporateAccounts.paymentMethod.infograph.cards.1.body':
    'corporateAccounts.paymentMethod.infograph.cards.1.body',
  'corporateAccounts.paymentMethod.infograph.cards.1.title':
    'corporateAccounts.paymentMethod.infograph.cards.1.title',
  'corporateAccounts.paymentMethod.infograph.cards.2.body':
    'corporateAccounts.paymentMethod.infograph.cards.2.body',
  'corporateAccounts.paymentMethod.infograph.cards.2.title':
    'corporateAccounts.paymentMethod.infograph.cards.2.title',
  'corporateAccounts.paymentMethod.infograph.subcontent':
    'corporateAccounts.paymentMethod.infograph.subcontent',
  'corporateAccounts.paymentMethod.update.success':
    'corporateAccounts.paymentMethod.update.success',
  'corporateAccounts.payments.actions.addAndAgree':
    'corporateAccounts.payments.actions.addAndAgree',
  'corporateAccounts.payments.actions.addPayment': 'corporateAccounts.payments.actions.addPayment',
  'corporateAccounts.payments.actions.editPayment':
    'corporateAccounts.payments.actions.editPayment',
  'corporateAccounts.payments.cardCountryCode': 'corporateAccounts.payments.cardCountryCode',
  'corporateAccounts.payments.cardCvc': 'corporateAccounts.payments.cardCvc',
  'corporateAccounts.payments.cardName': 'corporateAccounts.payments.cardName',
  'corporateAccounts.payments.cardNumber': 'corporateAccounts.payments.cardNumber',
  'corporateAccounts.payments.cardType': 'corporateAccounts.payments.cardType',
  'corporateAccounts.payments.create.error.cardAlreadyExpired':
    'corporateAccounts.payments.create.error.cardAlreadyExpired',
  'corporateAccounts.payments.create.error.cardNumberInvalid':
    'corporateAccounts.payments.create.error.cardNumberInvalid',
  'corporateAccounts.payments.create.error.cardNumberInvalidFormat':
    'corporateAccounts.payments.create.error.cardNumberInvalidFormat',
  'corporateAccounts.payments.create.error.cardNumberRequired':
    'corporateAccounts.payments.create.error.cardNumberRequired',
  'corporateAccounts.payments.create.error.cardTypeNotSupported':
    'corporateAccounts.payments.create.error.cardTypeNotSupported',
  'corporateAccounts.payments.create.error.cardValidationFailed':
    'corporateAccounts.payments.create.error.cardValidationFailed',
  'corporateAccounts.payments.create.error.countryCodeNotSupported':
    'corporateAccounts.payments.create.error.countryCodeNotSupported',
  'corporateAccounts.payments.create.error.countryCodeRequired':
    'corporateAccounts.payments.create.error.countryCodeRequired',
  'corporateAccounts.payments.create.error.cvvInvalidFormat':
    'corporateAccounts.payments.create.error.cvvInvalidFormat',
  'corporateAccounts.payments.create.error.cvvRequired':
    'corporateAccounts.payments.create.error.cvvRequired',
  'corporateAccounts.payments.create.error.expiryMonthInvalid':
    'corporateAccounts.payments.create.error.expiryMonthInvalid',
  'corporateAccounts.payments.create.error.expiryMonthRequired':
    'corporateAccounts.payments.create.error.expiryMonthRequired',
  'corporateAccounts.payments.create.error.expiryYearRequired':
    'corporateAccounts.payments.create.error.expiryYearRequired',
  'corporateAccounts.payments.create.error.expiryYearTooFarInTheFuture':
    'corporateAccounts.payments.create.error.expiryYearTooFarInTheFuture',
  'corporateAccounts.payments.create.error.limitExceeded':
    'corporateAccounts.payments.create.error.limitExceeded',
  'corporateAccounts.payments.create.error.nameOnCardInvalidFormat':
    'corporateAccounts.payments.create.error.nameOnCardInvalidFormat',
  'corporateAccounts.payments.create.error.nameOnCardRequired':
    'corporateAccounts.payments.create.error.nameOnCardRequired',
  'corporateAccounts.payments.create.error.paymentAccountAlreadyExists':
    'corporateAccounts.payments.create.error.paymentAccountAlreadyExists',
  'corporateAccounts.payments.create.error.paymentAccountNotFound':
    'corporateAccounts.payments.create.error.paymentAccountNotFound',
  'corporateAccounts.payments.create.error.unhandledError':
    'corporateAccounts.payments.create.error.unhandledError',
  'corporateAccounts.payments.create.error.zipCodeInvalidFormat':
    'corporateAccounts.payments.create.error.zipCodeInvalidFormat',
  'corporateAccounts.payments.create.success': 'corporateAccounts.payments.create.success',
  'corporateAccounts.payments.creditAccountBanner.button.cta':
    'corporateAccounts.payments.creditAccountBanner.button.cta',
  'corporateAccounts.payments.creditAccountBanner.description':
    'corporateAccounts.payments.creditAccountBanner.description',
  'corporateAccounts.payments.creditAccountBanner.description.cta':
    'corporateAccounts.payments.creditAccountBanner.description.cta',
  'corporateAccounts.payments.creditAccountBanner.title':
    'corporateAccounts.payments.creditAccountBanner.title',
  'corporateAccounts.payments.cvc': 'corporateAccounts.payments.cvc',
  'corporateAccounts.payments.description': 'corporateAccounts.payments.description',
  'corporateAccounts.payments.description.link': 'corporateAccounts.payments.description.link',
  'corporateAccounts.payments.emptyState.addCreditCardBtn':
    'corporateAccounts.payments.emptyState.addCreditCardBtn',
  'corporateAccounts.payments.emptyState.description':
    'corporateAccounts.payments.emptyState.description',
  'corporateAccounts.payments.emptyState.title': 'corporateAccounts.payments.emptyState.title',
  'corporateAccounts.payments.emptyState.videoLink':
    'corporateAccounts.payments.emptyState.videoLink',
  'corporateAccounts.payments.error.delete.notFound':
    'corporateAccounts.payments.error.delete.notFound',
  'corporateAccounts.payments.expiry': 'corporateAccounts.payments.expiry',
  'corporateAccounts.payments.expiryMonth': 'corporateAccounts.payments.expiryMonth',
  'corporateAccounts.payments.expiryYear': 'corporateAccounts.payments.expiryYear',
  'corporateAccounts.payments.form.cardCountryCodePlaceholder':
    'corporateAccounts.payments.form.cardCountryCodePlaceholder',
  'corporateAccounts.payments.form.cardCountryCodeRequired':
    'corporateAccounts.payments.form.cardCountryCodeRequired',
  'corporateAccounts.payments.form.cardCvcRequired':
    'corporateAccounts.payments.form.cardCvcRequired',
  'corporateAccounts.payments.form.cardCvcValidation':
    'corporateAccounts.payments.form.cardCvcValidation',
  'corporateAccounts.payments.form.cardExpiryRequired':
    'corporateAccounts.payments.form.cardExpiryRequired',
  'corporateAccounts.payments.form.cardExpiryValidation':
    'corporateAccounts.payments.form.cardExpiryValidation',
  'corporateAccounts.payments.form.cardNameLength':
    'corporateAccounts.payments.form.cardNameLength',
  'corporateAccounts.payments.form.cardNamePlaceholder':
    'corporateAccounts.payments.form.cardNamePlaceholder',
  'corporateAccounts.payments.form.cardNameRequired':
    'corporateAccounts.payments.form.cardNameRequired',
  'corporateAccounts.payments.form.cardNumberPlaceholder':
    'corporateAccounts.payments.form.cardNumberPlaceholder',
  'corporateAccounts.payments.form.cardNumberRequired':
    'corporateAccounts.payments.form.cardNumberRequired',
  'corporateAccounts.payments.form.cardNumberValidation':
    'corporateAccounts.payments.form.cardNumberValidation',
  'corporateAccounts.payments.form.cardTypePlaceholder':
    'corporateAccounts.payments.form.cardTypePlaceholder',
  'corporateAccounts.payments.form.cardTypeRequired':
    'corporateAccounts.payments.form.cardTypeRequired',
  'corporateAccounts.payments.form.creditCardTypeNotSupported':
    'corporateAccounts.payments.form.creditCardTypeNotSupported',
  'corporateAccounts.payments.form.disclaimer': 'corporateAccounts.payments.form.disclaimer',
  'corporateAccounts.payments.form.expiryMonthPlaceholder':
    'corporateAccounts.payments.form.expiryMonthPlaceholder',
  'corporateAccounts.payments.form.expiryYearPlaceholder':
    'corporateAccounts.payments.form.expiryYearPlaceholder',
  'corporateAccounts.payments.form.nameOnCardFormat':
    'corporateAccounts.payments.form.nameOnCardFormat',
  'corporateAccounts.payments.form.nameOnCardLength':
    'corporateAccounts.payments.form.nameOnCardLength',
  'corporateAccounts.payments.form.nameOnCardPlaceholder':
    'corporateAccounts.payments.form.nameOnCardPlaceholder',
  'corporateAccounts.payments.form.nameOnCardRequired':
    'corporateAccounts.payments.form.nameOnCardRequired',
  'corporateAccounts.payments.form.primaryCountryOfUse':
    'corporateAccounts.payments.form.primaryCountryOfUse',
  'corporateAccounts.payments.form.storedCredentialAgreement':
    'corporateAccounts.payments.form.storedCredentialAgreement',
  'corporateAccounts.payments.form.termsAgreement':
    'corporateAccounts.payments.form.termsAgreement',
  'corporateAccounts.payments.form.update': 'corporateAccounts.payments.form.update',
  'corporateAccounts.payments.form.zipCodePlaceholder':
    'corporateAccounts.payments.form.zipCodePlaceholder',
  'corporateAccounts.payments.form.zipCodeRequired':
    'corporateAccounts.payments.form.zipCodeRequired',
  'corporateAccounts.payments.generic.error': 'corporateAccounts.payments.generic.error',
  'corporateAccounts.payments.main.description': 'corporateAccounts.payments.main.description',
  'corporateAccounts.payments.modal.delete.areYouSure':
    'corporateAccounts.payments.modal.delete.areYouSure',
  'corporateAccounts.payments.modal.delete.lastCard':
    'corporateAccounts.payments.modal.delete.lastCard',
  'corporateAccounts.payments.nameOnCard': 'corporateAccounts.payments.nameOnCard',
  'corporateAccounts.payments.paymentAccountId': 'corporateAccounts.payments.paymentAccountId',
  'corporateAccounts.payments.spa.challenge.title':
    'corporateAccounts.payments.spa.challenge.title',
  'corporateAccounts.payments.spa.title': 'corporateAccounts.payments.spa.title',
  'corporateAccounts.payments.spa.title.edit': 'corporateAccounts.payments.spa.title.edit',
  'corporateAccounts.payments.spa.verification.content':
    'corporateAccounts.payments.spa.verification.content',
  'corporateAccounts.payments.spa.verification.title':
    'corporateAccounts.payments.spa.verification.title',
  'corporateAccounts.payments.title': 'corporateAccounts.payments.title',
  'corporateAccounts.payments.toast.paymentMethodDeleted':
    'corporateAccounts.payments.toast.paymentMethodDeleted',
  'corporateAccounts.payments.update.success': 'corporateAccounts.payments.update.success',
  'corporateAccounts.payments.zipCode': 'corporateAccounts.payments.zipCode',
  'corporateAccounts.reports.actions.clear': 'corporateAccounts.reports.actions.clear',
  'corporateAccounts.reports.actions.load': 'corporateAccounts.reports.actions.load',
  'corporateAccounts.reports.cardName': 'corporateAccounts.reports.cardName',
  'corporateAccounts.reports.cost': 'corporateAccounts.reports.cost',
  'corporateAccounts.reports.costCenter': 'corporateAccounts.reports.costCenter',
  'corporateAccounts.reports.costInfo': 'corporateAccounts.reports.costInfo',
  'corporateAccounts.reports.dateRange': 'corporateAccounts.reports.dateRange',
  'corporateAccounts.reports.dateRange.endDate': 'corporateAccounts.reports.dateRange.endDate',
  'corporateAccounts.reports.dateRange.startDate': 'corporateAccounts.reports.dateRange.startDate',
  'corporateAccounts.reports.day': 'corporateAccounts.reports.day',
  'corporateAccounts.reports.dayOfWeek': 'corporateAccounts.reports.dayOfWeek',
  'corporateAccounts.reports.email': 'corporateAccounts.reports.email',
  'corporateAccounts.reports.firstName': 'corporateAccounts.reports.firstName',
  'corporateAccounts.reports.lastName': 'corporateAccounts.reports.lastName',
  'corporateAccounts.reports.licensePlate': 'corporateAccounts.reports.licensePlate',
  'corporateAccounts.reports.localEndDateTime': 'corporateAccounts.reports.localEndDateTime',
  'corporateAccounts.reports.localStartDateTime': 'corporateAccounts.reports.localStartDateTime',
  'corporateAccounts.reports.lotId': 'corporateAccounts.reports.lotId',
  'corporateAccounts.reports.main.title': 'corporateAccounts.reports.main.title',
  'corporateAccounts.reports.maskedCardNumber': 'corporateAccounts.reports.maskedCardNumber',
  'corporateAccounts.reports.month': 'corporateAccounts.reports.month',
  'corporateAccounts.reports.month.placeholder': 'corporateAccounts.reports.month.placeholder',
  'corporateAccounts.reports.monthly': 'corporateAccounts.reports.monthly',
  'corporateAccounts.reports.monthly.generateReport':
    'corporateAccounts.reports.monthly.generateReport',
  'corporateAccounts.reports.monthly.infoNotice': 'corporateAccounts.reports.monthly.infoNotice',
  'corporateAccounts.reports.monthly.last30days': 'corporateAccounts.reports.monthly.last30days',
  'corporateAccounts.reports.monthly.last7days': 'corporateAccounts.reports.monthly.last7days',
  'corporateAccounts.reports.monthly.last90days': 'corporateAccounts.reports.monthly.last90days',
  'corporateAccounts.reports.monthly.reportJob.status.completed':
    'corporateAccounts.reports.monthly.reportJob.status.completed',
  'corporateAccounts.reports.monthly.reportJob.status.failed':
    'corporateAccounts.reports.monthly.reportJob.status.failed',
  'corporateAccounts.reports.monthly.reportJob.status.pending':
    'corporateAccounts.reports.monthly.reportJob.status.pending',
  'corporateAccounts.reports.monthly.reportType': 'corporateAccounts.reports.monthly.reportType',
  'corporateAccounts.reports.monthly.timeExported':
    'corporateAccounts.reports.monthly.timeExported',
  'corporateAccounts.reports.monthly.toast.generatingReport.content':
    'corporateAccounts.reports.monthly.toast.generatingReport.content',
  'corporateAccounts.reports.monthly.toast.generatingReport.title':
    'corporateAccounts.reports.monthly.toast.generatingReport.title',
  'corporateAccounts.reports.monthlyReports': 'corporateAccounts.reports.monthlyReports',
  'corporateAccounts.reports.netTotal': 'corporateAccounts.reports.netTotal',
  'corporateAccounts.reports.parkingLotName': 'corporateAccounts.reports.parkingLotName',
  'corporateAccounts.reports.parkingMinutes': 'corporateAccounts.reports.parkingMinutes',
  'corporateAccounts.reports.parkingRegionName': 'corporateAccounts.reports.parkingRegionName',
  'corporateAccounts.reports.parkingStatus': 'corporateAccounts.reports.parkingStatus',
  'corporateAccounts.reports.parkingVendorName': 'corporateAccounts.reports.parkingVendorName',
  'corporateAccounts.reports.paymentDate': 'corporateAccounts.reports.paymentDate',
  'corporateAccounts.reports.paymentStatus': 'corporateAccounts.reports.paymentStatus',
  'corporateAccounts.reports.phone': 'corporateAccounts.reports.phone',
  'corporateAccounts.reports.phoneNumber': 'corporateAccounts.reports.phoneNumber',
  'corporateAccounts.reports.searchInput.placeholder':
    'corporateAccounts.reports.searchInput.placeholder',
  'corporateAccounts.reports.tax': 'corporateAccounts.reports.tax',
  'corporateAccounts.reports.transactionId': 'corporateAccounts.reports.transactionId',
  'corporateAccounts.reports.transactions': 'corporateAccounts.reports.transactions',
  'corporateAccounts.reports.transactions.chooseDateRange':
    'corporateAccounts.reports.transactions.chooseDateRange',
  'corporateAccounts.reports.transactions.noResults':
    'corporateAccounts.reports.transactions.noResults',
  'corporateAccounts.reports.transactions.timeout.modal.content':
    'corporateAccounts.reports.transactions.timeout.modal.content',
  'corporateAccounts.reports.transactions.timeout.modal.title':
    'corporateAccounts.reports.transactions.timeout.modal.title',
  'corporateAccounts.reports.vehicleCountry': 'corporateAccounts.reports.vehicleCountry',
  'corporateAccounts.reports.vehicleVin': 'corporateAccounts.reports.vehicleVin',
  'corporateAccounts.search.validation.minimum': 'corporateAccounts.search.validation.minimum',
  'corporateAccounts.selfRegistration.actions.signIn':
    'corporateAccounts.selfRegistration.actions.signIn',
  'corporateAccounts.selfRegistration.activation.description':
    'corporateAccounts.selfRegistration.activation.description',
  'corporateAccounts.selfRegistration.activation.feedback':
    'corporateAccounts.selfRegistration.activation.feedback',
  'corporateAccounts.selfRegistration.activation.feedback.difficult':
    'corporateAccounts.selfRegistration.activation.feedback.difficult',
  'corporateAccounts.selfRegistration.activation.feedback.easy':
    'corporateAccounts.selfRegistration.activation.feedback.easy',
  'corporateAccounts.selfRegistration.activation.feedback.expected':
    'corporateAccounts.selfRegistration.activation.feedback.expected',
  'corporateAccounts.selfRegistration.activation.supportLink':
    'corporateAccounts.selfRegistration.activation.supportLink',
  'corporateAccounts.selfRegistration.activation.title':
    'corporateAccounts.selfRegistration.activation.title',
  'corporateAccounts.selfRegistration.emailVerificationInstruction':
    'corporateAccounts.selfRegistration.emailVerificationInstruction',
  'corporateAccounts.selfRegistration.form.agreement':
    'corporateAccounts.selfRegistration.form.agreement',
  'corporateAccounts.selfRegistration.form.creditCardNote':
    'corporateAccounts.selfRegistration.form.creditCardNote',
  'corporateAccounts.selfRegistration.form.title': 'corporateAccounts.selfRegistration.form.title',
  'corporateAccounts.selfRegistration.marketing.businessBenefits.one':
    'corporateAccounts.selfRegistration.marketing.businessBenefits.one',
  'corporateAccounts.selfRegistration.marketing.businessBenefits.three':
    'corporateAccounts.selfRegistration.marketing.businessBenefits.three',
  'corporateAccounts.selfRegistration.marketing.businessBenefits.two':
    'corporateAccounts.selfRegistration.marketing.businessBenefits.two',
  'corporateAccounts.selfRegistration.marketing.businessesTrust':
    'corporateAccounts.selfRegistration.marketing.businessesTrust',
  'corporateAccounts.selfRegistration.marketing.title':
    'corporateAccounts.selfRegistration.marketing.title',
  'corporateAccounts.selfRegistration.resendEmail.button':
    'corporateAccounts.selfRegistration.resendEmail.button',
  'corporateAccounts.selfRegistration.resendEmail.failed':
    'corporateAccounts.selfRegistration.resendEmail.failed',
  'corporateAccounts.selfRegistration.resendEmail.success':
    'corporateAccounts.selfRegistration.resendEmail.success',
  'corporateAccounts.selfRegistration.success.supportContact':
    'corporateAccounts.selfRegistration.success.supportContact',
  'corporateAccounts.selfRegistration.success.supportMessage':
    'corporateAccounts.selfRegistration.success.supportMessage',
  'corporateAccounts.selfRegistration.success.title':
    'corporateAccounts.selfRegistration.success.title',
  'corporateAccounts.selfRegistration.verification.completeCaptcha':
    'corporateAccounts.selfRegistration.verification.completeCaptcha',
  'corporateAccounts.selfRegistration.verification.failed':
    'corporateAccounts.selfRegistration.verification.failed',
  'corporateAccounts.selfRegistration.verification.failed.message':
    'corporateAccounts.selfRegistration.verification.failed.message',
  'corporateAccounts.selfRegistration.verification.linkExpired':
    'corporateAccounts.selfRegistration.verification.linkExpired',
  'corporateAccounts.selfRegistration.verification.linkExpired.message':
    'corporateAccounts.selfRegistration.verification.linkExpired.message',
  'corporateAccounts.selfRegistration.verification.resendVerification':
    'corporateAccounts.selfRegistration.verification.resendVerification',
  'corporateAccounts.selfRegistration.verification.success':
    'corporateAccounts.selfRegistration.verification.success',
  'corporateAccounts.selfRegistration.verification.success.message':
    'corporateAccounts.selfRegistration.verification.success.message',
  'corporateAccounts.selfRegistration.verification.success.portalLink':
    'corporateAccounts.selfRegistration.verification.success.portalLink',
  'corporateAccounts.server.domainErrors.RestrictedCorporateClient':
    'corporateAccounts.server.domainErrors.RestrictedCorporateClient',
  'corporateAccounts.sessions.main.description': 'corporateAccounts.sessions.main.description',
  'corporateAccounts.sessions.title': 'corporateAccounts.sessions.title',
  'corporateAccounts.staff.actions.add': 'corporateAccounts.staff.actions.add',
  'corporateAccounts.staff.search': 'corporateAccounts.staff.search',
  'corporateAccounts.staff.title': 'corporateAccounts.staff.title',
  'corporateAccounts.staff.title.add': 'corporateAccounts.staff.title.add',
  'corporateAccounts.subscription.cancelAccount': 'corporateAccounts.subscription.cancelAccount',
  'corporateAccounts.subscription.contactCustomerSuccess':
    'corporateAccounts.subscription.contactCustomerSuccess',
  'corporateAccounts.subscription.contactForPricing':
    'corporateAccounts.subscription.contactForPricing',
  'corporateAccounts.subscription.currentPlanTitle':
    'corporateAccounts.subscription.currentPlanTitle',
  'corporateAccounts.subscription.deactivateAccount':
    'corporateAccounts.subscription.deactivateAccount',
  'corporateAccounts.subscription.editPlan': 'corporateAccounts.subscription.editPlan',
  'corporateAccounts.subscription.enterpriseDeactivate.contactSupport':
    'corporateAccounts.subscription.enterpriseDeactivate.contactSupport',
  'corporateAccounts.subscription.enterpriseDeactivate.text':
    'corporateAccounts.subscription.enterpriseDeactivate.text',
  'corporateAccounts.subscription.enterpriseDeactivate.title':
    'corporateAccounts.subscription.enterpriseDeactivate.title',
  'corporateAccounts.subscription.error.contactSupport':
    'corporateAccounts.subscription.error.contactSupport',
  'corporateAccounts.subscription.error.tryAgain': 'corporateAccounts.subscription.error.tryAgain',
  'corporateAccounts.subscription.memberSince': 'corporateAccounts.subscription.memberSince',
  'corporateAccounts.subscription.monthlyPlan': 'corporateAccounts.subscription.monthlyPlan',
  'corporateAccounts.subscription.nextBillingCycle':
    'corporateAccounts.subscription.nextBillingCycle',
  'corporateAccounts.subscription.perDriver': 'corporateAccounts.subscription.perDriver',
  'corporateAccounts.subscription.selfRegisteredDeactivate.confirm.button':
    'corporateAccounts.subscription.selfRegisteredDeactivate.confirm.button',
  'corporateAccounts.subscription.selfRegisteredDeactivate.confirm.text':
    'corporateAccounts.subscription.selfRegisteredDeactivate.confirm.text',
  'corporateAccounts.subscription.selfRegisteredDeactivate.confirm.title':
    'corporateAccounts.subscription.selfRegisteredDeactivate.confirm.title',
  'corporateAccounts.subscription.selfRegisteredDeactivate.differentParkingApp':
    'corporateAccounts.subscription.selfRegisteredDeactivate.differentParkingApp',
  'corporateAccounts.subscription.selfRegisteredDeactivate.missingFeatures':
    'corporateAccounts.subscription.selfRegisteredDeactivate.missingFeatures',
  'corporateAccounts.subscription.selfRegisteredDeactivate.notExpected':
    'corporateAccounts.subscription.selfRegisteredDeactivate.notExpected',
  'corporateAccounts.subscription.selfRegisteredDeactivate.other':
    'corporateAccounts.subscription.selfRegisteredDeactivate.other',
  'corporateAccounts.subscription.selfRegisteredDeactivate.subTitle':
    'corporateAccounts.subscription.selfRegisteredDeactivate.subTitle',
  'corporateAccounts.subscription.selfRegisteredDeactivate.title':
    'corporateAccounts.subscription.selfRegisteredDeactivate.title',
  'corporateAccounts.subscription.selfRegisteredDeactivate.tooExpensive':
    'corporateAccounts.subscription.selfRegisteredDeactivate.tooExpensive',
  'corporateAccounts.subscription.terms': 'corporateAccounts.subscription.terms',
  'corporateAccounts.topBar.helpMenu.title': 'corporateAccounts.topBar.helpMenu.title',
  'corporateAccounts.topBar.profileMenu.account': 'corporateAccounts.topBar.profileMenu.account',
  'corporateAccounts.topBar.profileMenu.changeCorporations':
    'corporateAccounts.topBar.profileMenu.changeCorporations',
  'corporateAccounts.topBar.profileMenu.contact': 'corporateAccounts.topBar.profileMenu.contact',
  'corporateAccounts.topBar.profileMenu.editProfile':
    'corporateAccounts.topBar.profileMenu.editProfile',
  'corporateAccounts.topBar.profileMenu.email': 'corporateAccounts.topBar.profileMenu.email',
  'corporateAccounts.topBar.profileMenu.hello': 'corporateAccounts.topBar.profileMenu.hello',
  'corporateAccounts.topBar.profileMenu.help': 'corporateAccounts.topBar.profileMenu.help',
  'corporateAccounts.topBar.profileMenu.home': 'corporateAccounts.topBar.profileMenu.home',
  'corporateAccounts.topBar.profileMenu.phone': 'corporateAccounts.topBar.profileMenu.phone',
  'corporateAccounts.topBar.profileMenu.role': 'corporateAccounts.topBar.profileMenu.role',
  'corporateAccounts.topBar.profileMenu.signOut': 'corporateAccounts.topBar.profileMenu.signOut',
  'corporateAccounts.topBar.profileMenu.support': 'corporateAccounts.topBar.profileMenu.support',
  'corporateAccounts.user.activation.error': 'corporateAccounts.user.activation.error',
  'corporateAccounts.user.activation.success': 'corporateAccounts.user.activation.success',
  'corporateAccounts.user.limitExceeded.error': 'corporateAccounts.user.limitExceeded.error',
  'corporateAccounts.user.login.success': 'corporateAccounts.user.login.success',
  'corporateAccounts.user.registration.duplicateEmail':
    'corporateAccounts.user.registration.duplicateEmail',
  'corporateAccounts.user.registration.internalError':
    'corporateAccounts.user.registration.internalError',
  'corporateAccounts.user.registration.userEmailDomainNotValid':
    'corporateAccounts.user.registration.userEmailDomainNotValid',
  'corporateAccounts.users.role.customer_success': 'corporateAccounts.users.role.customer_success',
  'corporateAccounts.users.role.customer_support_tier_2':
    'corporateAccounts.users.role.customer_support_tier_2',
  'corporateAccounts.users.role.super_admin': 'corporateAccounts.users.role.super_admin',
  'corporateAccounts.vehicle.add.duplicate.error': 'corporateAccounts.vehicle.add.duplicate.error',
  'corporateAccounts.vehicle.add.error': 'corporateAccounts.vehicle.add.error',
  'corporateAccounts.vehicle.add.invalidCharacters':
    'corporateAccounts.vehicle.add.invalidCharacters',
  'corporateAccounts.vehicle.add.success': 'corporateAccounts.vehicle.add.success',
  'corporateAccounts.vehicle.delete.error': 'corporateAccounts.vehicle.delete.error',
  'corporateAccounts.vehicle.delete.success': 'corporateAccounts.vehicle.delete.success',
  'corporateAccounts.vehicle.importLimitExceeded.error':
    'corporateAccounts.vehicle.importLimitExceeded.error',
  'corporateAccounts.vehicle.totalLimitExceeded.error':
    'corporateAccounts.vehicle.totalLimitExceeded.error',
  'corporateAccounts.vehicle.update.error': 'corporateAccounts.vehicle.update.error',
  'corporateAccounts.vehicle.update.success': 'corporateAccounts.vehicle.update.success',
  'corporateAccounts.vehicles.actions.addVehicle': 'corporateAccounts.vehicles.actions.addVehicle',
  'corporateAccounts.vehicles.actions.deleteVehicleMessage':
    'corporateAccounts.vehicles.actions.deleteVehicleMessage',
  'corporateAccounts.vehicles.actions.deleteVehicles':
    'corporateAccounts.vehicles.actions.deleteVehicles',
  'corporateAccounts.vehicles.bulkDelete.success': 'corporateAccounts.vehicles.bulkDelete.success',
  'corporateAccounts.vehicles.country': 'corporateAccounts.vehicles.country',
  'corporateAccounts.vehicles.csvDownload.country':
    'corporateAccounts.vehicles.csvDownload.country',
  'corporateAccounts.vehicles.csvDownload.jurisdiction':
    'corporateAccounts.vehicles.csvDownload.jurisdiction',
  'corporateAccounts.vehicles.csvDownload.licensePlate':
    'corporateAccounts.vehicles.csvDownload.licensePlate',
  'corporateAccounts.vehicles.csvDownload.vin': 'corporateAccounts.vehicles.csvDownload.vin',
  'corporateAccounts.vehicles.emptyState.ImportVehiclesBtn':
    'corporateAccounts.vehicles.emptyState.ImportVehiclesBtn',
  'corporateAccounts.vehicles.emptyState.description':
    'corporateAccounts.vehicles.emptyState.description',
  'corporateAccounts.vehicles.emptyState.videoLink':
    'corporateAccounts.vehicles.emptyState.videoLink',
  'corporateAccounts.vehicles.export.error.modal.description':
    'corporateAccounts.vehicles.export.error.modal.description',
  'corporateAccounts.vehicles.export.error.modal.title':
    'corporateAccounts.vehicles.export.error.modal.title',
  'corporateAccounts.vehicles.export.fileName': 'corporateAccounts.vehicles.export.fileName',
  'corporateAccounts.vehicles.export.modal.description':
    'corporateAccounts.vehicles.export.modal.description',
  'corporateAccounts.vehicles.export.modal.title': 'corporateAccounts.vehicles.export.modal.title',
  'corporateAccounts.vehicles.export.success.notification.description':
    'corporateAccounts.vehicles.export.success.notification.description',
  'corporateAccounts.vehicles.export.success.notification.title':
    'corporateAccounts.vehicles.export.success.notification.title',
  'corporateAccounts.vehicles.form.add': 'corporateAccounts.vehicles.form.add',
  'corporateAccounts.vehicles.form.countryRequired':
    'corporateAccounts.vehicles.form.countryRequired',
  'corporateAccounts.vehicles.form.edit': 'corporateAccounts.vehicles.form.edit',
  'corporateAccounts.vehicles.form.licensePlatePlaceholder':
    'corporateAccounts.vehicles.form.licensePlatePlaceholder',
  'corporateAccounts.vehicles.form.licensePlateRequired':
    'corporateAccounts.vehicles.form.licensePlateRequired',
  'corporateAccounts.vehicles.form.licensePlateValidation':
    'corporateAccounts.vehicles.form.licensePlateValidation',
  'corporateAccounts.vehicles.form.new': 'corporateAccounts.vehicles.form.new',
  'corporateAccounts.vehicles.form.regionRequired':
    'corporateAccounts.vehicles.form.regionRequired',
  'corporateAccounts.vehicles.form.update': 'corporateAccounts.vehicles.form.update',
  'corporateAccounts.vehicles.form.vinValidation': 'corporateAccounts.vehicles.form.vinValidation',
  'corporateAccounts.vehicles.import.description': 'corporateAccounts.vehicles.import.description',
  'corporateAccounts.vehicles.import.fileName': 'corporateAccounts.vehicles.import.fileName',
  'corporateAccounts.vehicles.import.header.countryISO':
    'corporateAccounts.vehicles.import.header.countryISO',
  'corporateAccounts.vehicles.import.header.licensePlate':
    'corporateAccounts.vehicles.import.header.licensePlate',
  'corporateAccounts.vehicles.import.header.numberPlate':
    'corporateAccounts.vehicles.import.header.numberPlate',
  'corporateAccounts.vehicles.import.header.stateISO':
    'corporateAccounts.vehicles.import.header.stateISO',
  'corporateAccounts.vehicles.import.header.vin': 'corporateAccounts.vehicles.import.header.vin',
  'corporateAccounts.vehicles.import.step.2.content':
    'corporateAccounts.vehicles.import.step.2.content',
  'corporateAccounts.vehicles.import.title': 'corporateAccounts.vehicles.import.title',
  'corporateAccounts.vehicles.infograph.cards.0.title':
    'corporateAccounts.vehicles.infograph.cards.0.title',
  'corporateAccounts.vehicles.infograph.cards.1.body':
    'corporateAccounts.vehicles.infograph.cards.1.body',
  'corporateAccounts.vehicles.infograph.cards.1.title':
    'corporateAccounts.vehicles.infograph.cards.1.title',
  'corporateAccounts.vehicles.infograph.cards.2.body':
    'corporateAccounts.vehicles.infograph.cards.2.body',
  'corporateAccounts.vehicles.infograph.cards.2.title':
    'corporateAccounts.vehicles.infograph.cards.2.title',
  'corporateAccounts.vehicles.infograph.subcontent':
    'corporateAccounts.vehicles.infograph.subcontent',
  'corporateAccounts.vehicles.licensePlate': 'corporateAccounts.vehicles.licensePlate',
  'corporateAccounts.vehicles.modal.content.bulkDelete':
    'corporateAccounts.vehicles.modal.content.bulkDelete',
  'corporateAccounts.vehicles.modal.title.bulkDelete':
    'corporateAccounts.vehicles.modal.title.bulkDelete',
  'corporateAccounts.vehicles.title': 'corporateAccounts.vehicles.title',
  'corporateAccounts.vehicles.vin': 'corporateAccounts.vehicles.vin',
  'corporateAcctounts.vehicles.description': 'corporateAcctounts.vehicles.description',
};
